import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import ConfigsModal from '../../components/Shared/Modals/ConfigsModal';
import ConfigsTable from '../../components/Table/ConfigsTable/ConfigsTable';
import { getAllConfigs, addConfig, updateConfig } from '../../services/operations/config';
import { setConfig } from '../../slices/configSlice';

const AddConfig = () => {
    
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { allConfigs, config } = useSelector((state) => state.configs)

    useEffect(() => {
        dispatch(getAllConfigs())
    }, [dispatch])

    useEffect(() => {
        dispatch(setConfig({}))
    }, [dispatch]);

    useEffect(() => {
        reset(config)
    }, [reset, config]);

    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            key: '',
            value: '',
        }
        
        dispatch(setConfig(data))
        setIsEdit(false)
        setModalOpen(true);
    }

    const handleOnSubmit = async (data) => {

        if (isEdit) {
            const { key, value } = data;
            dispatch(updateConfig(config?.id, key, value, setModalOpen));
        }
        else {
            const { key, value } = data;
            dispatch(addConfig(key, value, setModalOpen));
        }
    };


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add Config</button>
                        </div>
                        {allConfigs && allConfigs.length > 0 ? (
                            <>
                                <ConfigsTable 
                                    configs={allConfigs}
                                    setIsEdit={setIsEdit}
                                    setModalOpen={setModalOpen}
                                    modalOpen={modalOpen} 
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Config Keys Stored in database
                            </div>
                        )}
                        <ConfigsModal title={isEdit ? 'Edit config' : 'Create config'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Key</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="key"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="key"
                                                    type="text"
                                                    name="key"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Key"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'key is required',
                                            }}
                                        />
                                        {isSubmitted && errors.key && <p className="text-red-600 text-sm italic">{errors.key.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>value</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="value"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="value"
                                                    type="text"
                                                    name="value"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Value"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'value is required',
                                            }}
                                        />
                                        {isSubmitted && errors.value && <p className="text-red-600 text-sm italic">{errors.value.message}</p>}
                                    </div>
                                </div>
                               
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Add Config Key</button>
                                    </div>
                                </div>
                            </form>
                        </ConfigsModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddConfig