import React from 'react';
import DislikedProblemsItem from './DislikedProblemItems';

const DislikedProblemsTable = ({ dislikedProblems }) => {
    return (
        <div className="font-primary relative w-full">
            <div className='bg-white dark:bg-dark_50 rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full divide-y divide-gray-200 overflow-y-auto">
                        <thead className="text-xs uppercase text-[#8C8C8C]">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Problem Id</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Name</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Dislikes</div>
                                </th>
                            </tr>
                        </thead>
                            {
                                dislikedProblems?.map((problem, index) => {
                                    return (
                                        <DislikedProblemsItem
                                            num={index + 1}
                                            name={problem.name}
                                            dislikes={problem.dislikes}
                                            p_id={problem.problem_id}
                                        />
                                    )                
                                })
                            }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default DislikedProblemsTable;
