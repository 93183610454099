import React, { useEffect, useState } from 'react';
import DoubtCostTable from '../../components/Table/CommentsTable/DoubtCostTable';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getJudgeStats } from '../../services/operations/JudgeStats';

const JudgeStats = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const runSubmitStats = useSelector((state) => state.judgeStats.runSubmitStats);

  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  // Initially set startDate and endDate to show 1 month data from current date
  const [startDate, setStartDate] = useState(oneMonthAgo.toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(today.toISOString().slice(0, 10));

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(getJudgeStats(startDate, endDate));
    }
  }, [dispatch, startDate, endDate]);

  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
          <div className='flex items-center space-x-4 mb-4'>
            <input
              type='date'
              className='border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type='date'
              className='border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <DoubtCostTable stats={runSubmitStats} type='judgeStats' />
        </div>
      </div>
    </div>
  );
};

export default JudgeStats;
