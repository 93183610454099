import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allAffiliateSales: [],
};

const referralSlice = createSlice({
    name: "referrals",
    initialState: initialState,
    reducers: {
        setAllAffiliateSales(state, value) {
            state.allAffiliateSales = value.payload;
        },
    },
});

export const {  setAllAffiliateSales } = referralSlice.actions;
export default referralSlice.reducer;