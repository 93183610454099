import React from 'react'

const DoubtCostTableItem = (props) => {
    const { num, date, totalCost } = props

    const formatDate = (date) => {
        const d = new Date(date);
        console.log("date frontend: ", d);
        if (isNaN(d.getTime())) {
            throw new Error("Invalid date format");
        }
        return d.toISOString().split('T')[0];
    };

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(date)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {totalCost}
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default DoubtCostTableItem