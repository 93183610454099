import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import ReferralsTable from '../../components/Table/ReferralsTable/ReferralsTable'
import { getAffiliateSales, generatePaymentSheet } from '../../services/operations/referrals'

const Affiliates = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const dispatch = useDispatch();
    const { allAffiliateSales } = useSelector((state) => state.referrals);
    
    useEffect(() => {
        dispatch(getAffiliateSales());
    }, [dispatch]);
    // console.log("allAffiliateSales: ", allAffiliateSales);

    const handleGenerateSheet = (e) => {
        e.stopPropagation()
        dispatch(generatePaymentSheet())
    }

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {allAffiliateSales && allAffiliateSales.length > 0 ? (
                            <>
                             <div className='flex mt-4 mb-8 flex-row justify-between'>
                                <button onClick={handleGenerateSheet} className='btn bg-green-500 hover:bg-green-600 active:bg-green-700 border-green-500 hover:border-green-600 active:border-green-700 text-white transition-all duration-300 ease-in-out'>
                                    Generate Sheet
                                </button>
                             </div>
                                <ReferralsTable affiliates={allAffiliateSales} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Affiliates
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div> 
    )
}

export default Affiliates;