import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { Controller, useForm } from 'react-hook-form';
import CouponsTable from '../../components/Table/CouponsTable/CouponsTable';
import { setCouponCode } from '../../slices/premiumSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addCoupon, getAllCoupons, updateCoupon } from '../../services/operations/premium';

const CouponCode = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { allCouponCodes, couponCode } = useSelector((state) => state.premium)


    useEffect(() => {
        dispatch(getAllCoupons())
    }, [dispatch]);

    useEffect(() => {
        dispatch(setCouponCode({}))
    }, [dispatch]);

    useEffect(() => {
        reset(couponCode)
    }, [reset, couponCode]);


    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            name: '',
            discount: '',
            isActive: '',
            isPublic: '',
            maxCount: '',
        }


        dispatch(setCouponCode(data))
        setIsEdit(false)
        setModalOpen(true);
    }

    const onSubmit = async (data) => {

        if (isEdit) {
            data.discount = parseInt(data.discount);
            data.isActive = parseInt(data.isActive);
            data.isPublic = parseInt(data.isPublic);
            data.maxCount = parseInt(data.maxCount);
            const { discount, isActive,isPublic, maxCount, name } = data;
            dispatch(updateCoupon(couponCode?.id, discount, isActive,isPublic, maxCount, name, setModalOpen))

        } else {
            data.discount = parseInt(data.discount);
            data.isActive = parseInt(data.isActive);
            data.isPublic = parseInt(data.isPublic);
            data.maxCount = parseInt(data.maxCount);
            const { discount, isActive,isPublic, maxCount, name } = data;
            dispatch(addCoupon(discount, isActive,isPublic, maxCount, name, setModalOpen))

        }

    }

    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add</button>
                        </div>
                        <CategoryModal title={isEdit ? 'Edit Coupon' : 'Add Coupon'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='name'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Discount</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="discount"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="discount"
                                                    type="number"
                                                    name="discount"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='discount'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'discount is required',
                                            }}
                                        />
                                        {isSubmitted && errors.discount && <p className="text-red-600 text-sm italic">{errors.discount.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Max Count</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="maxCount"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="maxCount"
                                                    type="number"
                                                    name="maxCount"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='maxCount'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'maxCount is required',
                                            }}
                                        />
                                        {isSubmitted && errors.maxCount && <p className="text-red-600 text-sm italic">{errors.maxCount.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Active Status</span>

                                    <div className="w-full px-3">
                                        <Controller
                                            name="isActive"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="">Select Active Status...</option>
                                                    <option value={1}>Active</option>
                                                    <option value={0}>InActive</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Active is required',
                                            }}
                                        />
                                        {errors.isActive && <p className="text-red-600 text-sm italic">{errors.isActive.message}</p>}
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Public Status</span>

                                    <div className="w-full px-3">
                                        <Controller
                                            name="isPublic"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="">Select Public Status...</option>
                                                    <option value={1}>Enabled</option>
                                                    <option value={0}>Disabled</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Public is required',
                                            }}
                                        />
                                        {errors.isPublic && <p className="text-red-600 text-sm italic">{errors.isPublic.message}</p>}
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                        {allCouponCodes && allCouponCodes.length > 0 ? (
                            <>
                                <CouponsTable setIsEdit={setIsEdit} topics={allCouponCodes} setModalOpen={setModalOpen} modalOpen={modalOpen} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Coupons
                            </div>
                        )}

                    </div>
                </main>
            </div>
        </div>
    )
}

export default CouponCode