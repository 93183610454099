import { setRunSubmitStats } from '../../slices/judgeStatsSlice';
import { judgeStatsEndpoints } from '../api';
import { apiConnector } from '../apiConnector';
import toast from 'react-hot-toast';

const { GET_JUDGE_STATS } = judgeStatsEndpoints;

export function getJudgeStats(startDate, endDate) {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        'GET',
        `${GET_JUDGE_STATS}?start_date=${startDate}&end_date=${endDate}`,
      );

      if (response?.data) {
        const stats = response.data.map(({ date, total_runs, total_submits }) => ({
          date: new Date(date).toISOString().split('T')[0],
          runCount: total_runs,
          submitCount: total_submits,
        }));

        dispatch(setRunSubmitStats(stats));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching judge stats:', error);
      toast.error('Failed to fetch judge stats');
    }
  };
}
