import React from 'react'
import NotificationsTableItem from './NotificationTableItem'

const NotificationsTable = ({ notifications, setModalOpen, setIsEdit }) => {
    return (
        <div className="  font-primary  relative w-full">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Title</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Message</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  w-1/3 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Emails</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            notifications?.map((data) => {
                                return (
                                    <NotificationsTableItem
                                        key={data?.notification_id}
                                        id={data?.notification_id}
                                        title={data?.title}
                                        message={data?.message}
                                        emails={data?.userId || []}
                                        setModalOpen={setModalOpen}
                                        setIsEdit={setIsEdit}
                                    />
                                )
                            })
                        }
                        
                    </table>

                </div>
            </div>
        </div>
    )
}

export default NotificationsTable


