import React, { useEffect, useState } from 'react'
import CategoryModal from '../../components/Shared/Modals/CategoryModal'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { addPlan, getAllPlans, updatePlan } from '../../services/operations/premium';
import { useDispatch, useSelector } from 'react-redux';
import { setPlan } from '../../slices/premiumSlice';
import PlansTable from '../../components/Table/PlansTable/PlansTable';

const Plans = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { allPlans, plan } = useSelector((state) => state.premium)


    useEffect(() => {
        dispatch(getAllPlans())
    }, [dispatch]);
    useEffect(() => {
        dispatch(setPlan({}))
    }, [dispatch]);

    useEffect(() => {
        reset(plan)
    }, [reset, plan]);
    // console.log(allPlans)


    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            name: '',
            price: '',
        }


        dispatch(setPlan(data))
        setIsEdit(false)
        setModalOpen(true);
    }

    const onSubmit = async (data) => {

        if (isEdit) {
            data.price = parseInt(data.price);
            const { name, price } = data;
            dispatch(updatePlan(plan?.id, name, price, setModalOpen))

        } else {
            data.price = parseInt(data.price);
            const { name, price } = data;
            dispatch(addPlan(name, price, setModalOpen))

        }

    }

    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add</button>
                        </div>
                        <CategoryModal title={isEdit ? 'Edit Plan' : 'Add Plan'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='name'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Price</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="price"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="price"
                                                    type="number"
                                                    name="price"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='price'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Price is required',
                                            }}
                                        />
                                        {isSubmitted && errors.price && <p className="text-red-600 text-sm italic">{errors.price.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                        {allPlans && allPlans.length > 0 ? (
                            <>
                                <PlansTable setIsEdit={setIsEdit} topics={allPlans} setModalOpen={setModalOpen} modalOpen={modalOpen} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Plan
                            </div>
                        )}

                    </div>
                </main>
            </div>
        </div>
    )
}

export default Plans