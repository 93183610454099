import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allReportedBugs: [],
    problemBugs: { data: [], currentPage: 1 },
    editorialBugs: { data: [], currentPage: 1 },
    videoBugs: { data: [], currentPage: 1 },
    techBugs: { data: [], currentPage: 1 },
    othersBugs: { data: [], currentPage: 1 },
    selectedBug: null,
};

const bugSlice = createSlice({
    name: "bugs",
    initialState: initialState,
    reducers: {
        setAllReportedBugs(state, value) {
            state.allReportedBugs = value.payload;
        },
        setProblemBugs(state, action) {
            state.problemBugs.data = action.payload.bugs;
            state.problemBugs.currentPage = action.payload.page;
        },
        setEditorialBugs(state, action) {
            state.editorialBugs.data = action.payload.bugs;
            state.editorialBugs.currentPage = action.payload.page;
        },
        setVideoBugs(state, action) {
            state.videoBugs.data = action.payload.bugs;
            state.videoBugs.currentPage = action.payload.page;
        },
        setTechBugs(state, action) {
            state.techBugs.data = action.payload.bugs;
            state.techBugs.currentPage = action.payload.page;
        },
        setOthersBugs(state, action) {
            state.othersBugs.data = action.payload.bugs;
            state.othersBugs.currentPage = action.payload.page;
        },
        setBug(state, action) { 
            state.selectedBug = action.payload;
        },
        clearSelectedBug(state) { 
            state.selectedBug = null;
        }
    },
});

export const {  setAllReportedBugs, setProblemBugs, setEditorialBugs, setVideoBugs, setTechBugs, setOthersBugs, setBug, clearSelectedBug } = bugSlice.actions;
export default bugSlice.reducer;