import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Transition from '../../../utils/Transition';
import { setConfig } from '../../../slices/configSlice'; 

function ConfigsModal({
    id,
    title,
    children,
    modalOpen,
    setModalOpen
}) {

    const modalContent = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!modalOpen || modalContent.current.contains(target)) return;
            setModalOpen(false);
            dispatch(setConfig({})); 
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!modalOpen || keyCode !== 27) return;
            dispatch(setConfig({})); 
            setModalOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <>
            <Transition
                className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
                show={modalOpen}
                enter="transition ease-out duration-200"
                enterStart="opacity-0"
                enterEnd="opacity-100"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                aria-hidden="true"
            />
            <Transition
                id={id}
                className="fixed inset-0 z-50  flex items-center my-4 justify-center transform px-4 sm:px-6"
                role="dialog"
                aria-modal="true"
                show={modalOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
                <div ref={modalContent} className="bg-white dark:bg-dark_50 rounded-xl shadow-lg overflow-y-scroll no-scrollbar max-w-[875px] w-full max-h-full border border-gray-200 dark:border-zinc-800">
                    <div className="px-5 py-3 border-b border-zinc-200 dark:border-zinc-700 h-full">
                        <div className="flex justify-between items-center">
                            <div className="font-semibold text-zinc-800 dark:text-zinc-100 text-xl">{title}</div>
                            <button className="text-md rounded-lg relative inline-flex items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-brand active:shadow-none shadow-lg bg-gradient-to-tr from-red-600 to-red-500 hover:from-red-500 hover:to-red-500  border-red-700 text-white" onClick={(e) => { e.stopPropagation(); dispatch(setConfig({})); setModalOpen(false); }}>
                                Close
                            </button>
                        </div>
                    </div>
                    <div className='max-w-md mx-auto mt-4 mb-12 md:p-0 p-8 relative'>
                        {children}
                    </div>
                </div>
            </Transition>
        </>
    );
}

export default ConfigsModal;
