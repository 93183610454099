// eslint-disable-next-line no-unused-vars
import  { setAllNotifications, setNotification } from "../../slices/notificationSlice"
import { notificationEndpoints } from '../api'
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_NOTIFICATIONS, ADD_NOTIFICATION, DEL_NOTIFICATION, UPDATE_NOTIFICATION, SEND_EMAIL_TO_PREMIUM_USERS} = notificationEndpoints;

export function getAllNotifications() {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", GET_NOTIFICATIONS);
            // console.log(response.data)
            if (response.data.success){
                dispatch(setAllNotifications(response.data.all_notifications));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function addNotification(title, message, emails, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_NOTIFICATION, { title, message, emails })
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllNotifications());
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add notification");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateNotification(id, title, message, emails, setModalOpen ) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", UPDATE_NOTIFICATION + `${id}`, { title, message, emails });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllNotifications());
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update notification");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteNotification(id, setModalDeleteOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", DEL_NOTIFICATION + `${id}`);
            if(response.data.success) {
                dispatch(getAllNotifications());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete notification");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function sendEmailToPremiumUsers(title, message) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", SEND_EMAIL_TO_PREMIUM_USERS, { title, message })
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllNotifications());
            } else {
                toast.error(response.data.message || "Failed to send emails");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}
