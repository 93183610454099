import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteUser } from '../../services/operations/auth'

const UsersTableItem = (props) => {


    const dispatch = useDispatch()

    const handleDeleteUser = () => { 
        dispatch(deleteUser(props?.name))
    }
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.name}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.role}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleDeleteUser(e)} className='btn bg-red-500 text-white  border-gree-500'>Delete</button>
                    </div>
                </td>
            </tr>
        </tbody >
    )
}

export default UsersTableItem