import React from 'react'
import BugsTableItem from "./BugsTableItem"

const ReportedBugsTable = ({ category, bugs, setContent, setModalOpen, currentPage }) => {
    return (
        <div className="font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200 overflow-y-auto">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Reported By</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Priority</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Time</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Title</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Status</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Actions</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">View</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            bugs?.map((data, index) => {
                                return (
                                    <BugsTableItem  
                                        key={data?.report_id} 
                                        id={data?.report_id}
                                        num={index + 1}
                                        status={data?.status}
                                        title={data?.title}
                                        description={data?.description}
                                        reportedBy={data?.reported_by}
                                        email={data?.email}
                                        priority={data?.priority}
                                        time={data?.timestamp}
                                        category={category}
                                        currentPage={currentPage}
                                        setContent={setContent}
                                        setModalOpen={setModalOpen}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ReportedBugsTable