/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { htmlToMarkdown, markdownToHtml } from "./Parser";

import "react-quill/dist/quill.snow.css";
import { TOOLBAR_OPTIONS_DISCUSSION, TOOLBAR_OPTIONS_NOTES, TOOLBAR_OPTIONS_DRAFT, hasHtmlTags } from "../../utils/Utils";
import { useEffect } from "react";
import axios from "axios";

export default function RichEditor(props) {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value || "")
    }, [props.value])

    const reactQuillRef = useRef(null);

    const onChange = (content) => {
        setValue(content);

        if (props.onChange) {
            props.onChange({
                name: props.name,
                html: content,
                markdown: htmlToMarkdown(content)
            });
        }
    };

    useEffect(() => {
        if (props?.isSubmitted) {
            setValue('');
        }
    }, [props?.isSubmitted])
    useEffect(() => {
        if (reactQuillRef.current) {
            const quill = reactQuillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', selectLocalImage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectLocalImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*'); // Accept only images
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('image', file);
                const fileName = 'ProblemSetter'
                formData.append('fileName', fileName);

                // Adjust the endpoint as necessary
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL_PROD}/uploadImage`,
                        formData,
                        { headers: { 'Content-type': 'multipart/form-data' }, withCredentials: true, }
                    )
                    // console.log(response.data)
                    if (response.data && response.data.link) {
                        insertToEditor(response.data.link);
                    }
                    console.log(response.data)
                } catch (error) {
                    console.error('Error uploading image: ', error);
                }
            }
        };
    };

    const insertToEditor = (url) => {
        const range = reactQuillRef.current.getEditor().getSelection();
        reactQuillRef.current.getEditor().insertEmbed(range.index, 'image', url);
    };

    const TOOLBAR_OPTIONS =
        props.options === "Notes"
            ? TOOLBAR_OPTIONS_NOTES
            : props.options === "Draft"
                ? TOOLBAR_OPTIONS_DRAFT
                : TOOLBAR_OPTIONS_DISCUSSION;
    return (
        <ReactQuill
            ref={reactQuillRef}
            theme="snow"
            placeholder="Start writing..."
            modules={{
                toolbar: {
                    container: TOOLBAR_OPTIONS,
                },
            }}
            value={value ? value : ''}
            className={props?.height === 'rich' && 'rich-editor-height'}
            onChange={onChange}
        />
    )

}
