import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allSales: [],
    statewiseSales: [],
    salesInDuration: [],
    totals: {},
    salesData: [],
    daywiseSales: [],
    hourlySales: [],
    currentPage: 1,
    totalPages: 0,
    pageSize: 25,
};

const salesTrackerSlice = createSlice({
    name: "sales",
    initialState: initialState,
    reducers: {
        setAllSales(state, value) {
            state.allSales = value.payload;
        },
        setStatewiseSales(state, value) {
            state.statewiseSales = value.payload;
        },
        setSalesInDuration(state, value) {
            state.salesInDuration = value.payload;
        },
        setTotals(state, value) {
            state.totals = value.payload
        },
        setSalesData(state, action) {
            state.salesData = action.payload.salesData;
            state.totals = action.payload.totals;
            state.daywiseSales = action.payload.daywiseSales;
            state.hourlySales = action.payload.hourlySales;
            state.totalPages = Math.ceil(action.payload.totalCount / state.pageSize);
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
});

export const {  setAllSales, setStatewiseSales, setSalesInDuration, setTotals, setSalesData, setCurrentPage } = salesTrackerSlice.actions;
export default salesTrackerSlice.reducer;