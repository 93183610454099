import toast from 'react-hot-toast';
import { setAllSessions } from "../../slices/sessionsSlice";
import { sessionsEndpoints } from "../api";
import { apiConnector } from "../apiConnector";

const { GET_SESSIONS, ADD_SESSION, DEL_SESSION, UPDATE_SESSION, GET_SESSION } = sessionsEndpoints;

export function getAllSessions() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_SESSIONS);
            if (response.data.success) {
                dispatch(setAllSessions(response.data.all_sessions));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getSession(id, setContent) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_SESSION + `${id}`);
            if (response.data.success) {
                setContent(response.data.emails);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function addSession(meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_SESSION, { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail })
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllSessions());
                if (setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add session");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateSession(id, meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", UPDATE_SESSION + `${id}`, { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllSessions());
                if (setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update session");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteSession(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", DEL_SESSION + `${id}`);
            if (response.data.success) {
                dispatch(getAllSessions());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete session");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}