import { setDaywiseDoubtCost, setDiscussions } from "../../slices/commentSlice"
import { commentsEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_DOUBT_COST_DAYWISE, GET_DISCUSSIONS, DEL_DISCUSSION } = commentsEndpoints;

export function getDoubtCost(start_date, end_date) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_DOUBT_COST_DAYWISE}?start_date=${start_date}&end_date=${end_date}`);
            if (response.data.success){
                dispatch(setDaywiseDoubtCost(response.data.costs));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getDiscussions(start_date, end_date, pageNumber = 1) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_DISCUSSIONS}?page=${pageNumber}?start_date=${start_date}&end_date=${end_date}`);
            if(response.data.success){
                dispatch(setDiscussions({
                    discussions: response.data.discussions,
                    page: pageNumber
                }));           
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function delDiscussion(id, setModalDeleteOpen, pageNumber) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("DELETE", `${DEL_DISCUSSION}?d_id=${id}`);
            if(response.data.success) {
                dispatch(getDiscussions(pageNumber));
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete discussion");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}