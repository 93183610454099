import { Route, Routes } from 'react-router-dom'
import Admin from './pages/Admin/Admin'
import Login from './pages/Login/Login'
import Category from './pages/ManageSyllabus/Category'
import Problems from './pages/ManageSyllabus/Problems'
import SubCategory from './pages/ManageSyllabus/SubCategory'
import AddSession from './pages/ManageSessions/AddSession'
import AddNotification from './pages/ManageNotifications/AddNotification'
import NotFound from './pages/NotFound/NotFound'
import EditorialContent from './pages/SyllabusContent/Editorials/EditorialContent'
import Editorials from './pages/SyllabusContent/Editorials/Editorials'
import ProblemSetter from './pages/SyllabusContent/ProblemSetter/ProblemSetter'
import VideoContent from './pages/SyllabusContent/VideoContent'
import ProblemSetterForm from './pages/SyllabusContent/ProblemSetter/ProblemSetterForm'
import AddUser from './pages/AddUser/AddUser'
import ProblemSetterPreview from './pages/SyllabusContent/ProblemSetter/ProblemSetterPreview'
import CreateEditor from './pages/CreateEditor/CreateEditor'
import ProtectedRoute from './utils/ProtectedRoute'
import EditorialContentPreview from './pages/SyllabusContent/Editorials/EditorialContentPreview'
import { useDispatch } from 'react-redux';
import { useEffect } from 'react'
import { getAllCategories, getAllDeletedProblems, getAllProblems, getAllSubCategories } from './services/operations/syllabus'
import Plans from './pages/ManagePremium/Plans'
import CouponCode from './pages/ManagePremium/CouponCode'
import DeletedProb from './pages/ManageSyllabus/DeletedProb'
import UploadTextFile from './pages/UploadTextFile/UploadTextFile'
import UploadImage from './pages/UploadImage/UploadImage'
import Affiliates from './pages/ManagePremium/Affiliates'
import CreateImageSlider from './pages/CreateImageSlider/CreateImageSlider'
import GrantAccess from './pages/ManagePremium/GrantAccess'
// import SalesTabsRender from './pages/ManagePremium/SalesTracker/SalesTabs'
import AddConfig from './pages/ManagePremium/Configs'
import DoubtCostInfo from './pages/ManagePremium/comments'
import Discussions from './pages/ManagePremium/Comments/Discussions'
import TabsRender from './pages/ManageBugReports/BugsTab'
import DislikedProblems from './pages/ManageSyllabus/DislikedProblems'
import JudgeStats from './pages/ManagePremium/JudgeStats'
import ErrorDashboard from './pages/Errors/Errors'



const App = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllCategories())
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllProblems())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllDeletedProblems())
    })

    useEffect(() => {
        dispatch(getAllSubCategories())
    }, [dispatch]);

    return (
      <div>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path='/admin' element={<Admin />} />
            <Route path='/create-editor' element={<CreateEditor />} />
            <Route path='/manage-syllabus/category' element={<Category />} />
            <Route path='/manage-syllabus/sub-category' element={<SubCategory />} />
            <Route path='/manage-syllabus/problems' element={<Problems />} />
            <Route path='/manage-syllabus/deletedProblems' element={<DeletedProb />} />
            <Route path='/manage-syllabus/dislikedproblems' element={<DislikedProblems />} />
            <Route path='/syllabus-content/video' element={<VideoContent />} />
            <Route path='/syllabus-content/editorials' element={<Editorials />} />
            <Route path='/syllabus-content/editorials/:id' element={<EditorialContent />} />
            <Route
              path='/syllabus-content/editorials/:id/preview'
              element={<EditorialContentPreview />}
            />
            <Route path='/syllabus-content/problem-setter' element={<ProblemSetter />} />
            <Route path='/syllabus-content/problem-setter/:id' element={<ProblemSetterForm />} />
            <Route
              path='/syllabus-content/problem-setter/:id/preview'
              element={<ProblemSetterPreview />}
            />
            <Route path='/manage-sessions' element={<AddSession />} />
            <Route path='/manage-notifications' element={<AddNotification />} />
            <Route path='/add-user' element={<AddUser />} />
            <Route path='/manage-premium/add-config' element={<AddConfig />} />
            <Route path='/manage-premium/plans' element={<Plans />} />
            <Route path='/manage-premium/coupons' element={<CouponCode />} />
            <Route path='/manage-premium/affiliates' element={<Affiliates />} />
            <Route path='/manage-premium/privileged-users' element={<GrantAccess />} />
            <Route path='/reported-bugs' element={<TabsRender />} />
            {/* <Route
                        path='/manage-premium/total-sales'
                        element={
                            <SalesTabsRender />
                        }
                    /> */}
            <Route path='/manage-premium/doubt-costs' element={<DoubtCostInfo />} />
            <Route path='/manage-premium/judge-stats' element={<JudgeStats />} />
            <Route path='/manage-premium/discussions' element={<Discussions />} />
            <Route path='/upload-text-file' element={<UploadTextFile />} />
            <Route path='/upload-image' element={<UploadImage />} />
            <Route path='/create-image-slider' element={<CreateImageSlider />} />
            <Route path='/errors' element={ < ErrorDashboard /> } />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    );
}

export default App