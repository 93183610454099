import React from 'react'
import UsersTableItem from './UsersTableItem'

const UsersTable = ({ users, setModalOpen, setIsEdit }) => {
    return (
        <div className="  font-primary  relative w-full">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr> 
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Name</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Email</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Plan Name</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Validity</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">isPremium</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            users?.map((data) => {
                                return (
                                    <UsersTableItem
                                        key={data?.user_id}
                                        name={data?.name}
                                        email={data?.email}
                                        planName={data?.plan_name}
                                        validity={data?.validity}
                                        isPremium={data?.isPremium}
                                        medium={data?.medium}
                                        userId={data?.user_id}
                                        setModalOpen={setModalOpen}
                                        setIsEdit={setIsEdit}
                                    />
                                )
                            })
                        }
                        
                    </table>

                </div>
            </div>
        </div>
    )
}

export default UsersTable


