import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import { Controller, useForm } from 'react-hook-form';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { addVideoAndThumb } from '../../services/operations/syllabus';
import ContentAccordion from '../../components/Accordion/ContentAccordion';
const VideoContent = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [parts, setParts] = useState({});
    const [defaultValues, setDefaultValues] = useState({});

    // const dispatch = useDispatch()

    // const handleModalOpen = (e) => {
    //     e.stopPropagation();

    //     setModalOpen(true);
    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm({ defaultValues: '' });
    // }
    const {isProd} = useSelector((state) => state.auth)
    const { allProblems } = useSelector((state) => state.syllabus)
    const dispatch = useDispatch()


    useEffect(() => {
        const newDefaultValues = {};
        parts?.parts?.forEach((part, index) => {
            newDefaultValues[`video${index + 1}`] = part.video_link;
            newDefaultValues[`thumbnail${index + 1}`] = part.thumbnail_link;
        });
        setDefaultValues(newDefaultValues);
    }, [parts]);

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const onSubmit = async (data) => {

        const updatedParts = parts?.parts?.map((part, index) => {
            return {
                problem_id: parts?.problem_id,
                partId: part.partId,
                video_link: data[`video${index + 1}`],
                thumbnail_link: data[`thumbnail${index + 1}`]
            };
        });

        dispatch(addVideoAndThumb(updatedParts, setModalOpen, isProd))
    }

    const updatedAllProblems = allProblems && allProblems.length > 0 && allProblems.map(category => {
       
        if (category && category.subcategories) {
            const filteredSubcategories = category.subcategories
                .map(subcategory => {

                    if (subcategory && subcategory.problems) {
                        const filteredProblems = subcategory.problems.filter(problem => problem.hasIDE !== 1);
                        return {
                            ...subcategory,
                            problems: filteredProblems
                        };
                    }
                    return subcategory;
                })
                .filter(subcategory => subcategory.problems && subcategory.problems.length > 0);

            return {
                ...category,
                subcategories: filteredSubcategories
            };
        }
        return category;
    });

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-[#f5f5f5]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <span className='text-2xl'>Video and Thumbnail</span>
                            {/*  <div className=" relative w-fit">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-white placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500"
                                    type="search" placeholder="Search…"
                                // value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="absolute inset-0 right-auto group" aria-label="Search">
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div> */}
                        </div>
                        {updatedAllProblems && updatedAllProblems.length > 0 ? (
                            updatedAllProblems.map((categories) => (
                                <div key={categories.id}>
                                    <ContentAccordion
                                        title={categories?.name}>

                                        {categories?.subcategories?.length > 0 && categories?.subcategories?.map((subCategories) => (
                                            <div key={subCategories.id} className='p-2'>

                                                <ContentAccordion
                                                    title={subCategories.name}>

                                                    <div className='p-3'>
                                                        <CategoryTable setParts={setParts} page="Video" topics={subCategories.problems?.length > 0 && subCategories.problems} setModalOpen={setModalOpen} modalOpen={modalOpen} />
                                                    </div>


                                                </ContentAccordion>
                                            </div>
                                        ))}

                                    </ContentAccordion>
                                </div>
                            ))) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Problems to show
                            </div>
                        )}
                        <CategoryModal title='Add Video Links' setModalOpen={setModalOpen} modalOpen={modalOpen}>

                            {parts?.parts?.length === 0 ? <div className='flex justify-center items-center text-4xl dark:text-zinc-300'> No Parts to show</div> :
                                <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                    {parts?.parts?.map((prt, index) => (
                                        <div key={index} className="flex justify-between gap-x-4 mt-6">
                                            <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>{prt.name} - Video {index + 1}</span>
                                                <div className="w-full px-3">
                                                    <Controller
                                                        name={`video${index + 1}`}
                                                        control={control}
                                                        defaultValue={defaultValues[`video${index + 1}`]}
                                                        render={({ field }) => (
                                                            <textarea
                                                                rows={4}
                                                                id={`video${index + 1}`}
                                                                type="text"
                                                                name={`video${index + 1}`}
                                                                className="form-input w-full text-gray-300"
                                                                placeholder={`video${index + 1}`}
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                    {isSubmitted && errors[`video${index + 1}`] && <p className="text-red-600 text-sm italic">{errors[`video${index + 1}`].message}</p>}
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>{prt.name} - Thumbnail {index + 1}</span>
                                                <div className="w-full px-3">
                                                    <Controller
                                                        name={`thumbnail${index + 1}`}
                                                        control={control}
                                                        defaultValue={defaultValues[`thumbnail${index + 1}`]}
                                                        render={({ field }) => (
                                                            <textarea
                                                                rows={4}
                                                                id={`thumbnail${index + 1}`}
                                                                type="text"
                                                                name={`thumbnail${index + 1}`}
                                                                className="form-input w-full text-gray-300"
                                                                placeholder={`thumbnail${index + 1}`}
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                    {isSubmitted && errors[`thumbnail${index + 1}`] && <p className="text-red-600 text-sm italic">{errors[`thumbnail${index + 1}`].message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            }

                        </CategoryModal>



                    </div>
                </main>
            </div>
        </div>
    )
}

export default VideoContent