import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { Controller, useForm } from 'react-hook-form';
import { BadgeX, Plus } from 'lucide-react';
import { addProblem, updateProblem } from '../../services/operations/syllabus';
import ContentAccordion from '../../components/Accordion/ContentAccordion';

const Problems = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [hasIDE, setHasIDE] = useState(1);

    const {isProd} = useSelector((state) => state.auth)

    const { allProblems, allCategories, allSubCategories, problem } = useSelector((state) => state.syllabus)
    const [additionalParts, setAdditionalParts] = useState([]);
    const { handleSubmit, control, setValue, formState: { errors, isSubmitted }, reset } = useForm({
        defaultValues: {
            ...problem,
            subCategory: problem.subCategory // Set the default value for subCategory
        }
    });
    // console.log(problem)
    useEffect(() => {
        const data = problem?.hasIDE === 1 ? true : false
        setHasIDE(data)
    }, [problem?.hasIDE, problem.problem_id])
    useEffect(() => {
        if (problem) {
            /*   reset({
                  category: problem.category || '',
                  subCategory: problem.subCategory || '',
                  name: problem.name || '',
                  rank: problem.rank || '',
                  points: problem.points || '',
                  // Reset Part1 and additional parts fields based on problem data
                  ...problem?.parts?.reduce((acc, part, index) => {
                      acc[`part${index + 1}`] = part.name;
                      return acc;
                  }, {}),
                  // Reset additional parts to empty strings if there are less parts than before
                  ...additionalParts.reduce((acc, part, index) => {
                      acc[`part${index + 2}`] = ''; // Reset additional parts to empty string
                      return acc;
                  }, {})
              }); */
            reset({
                category: problem.category || '',
                subCategory: problem.subCategory || '',
                name: problem.name || '',
                rank: problem.rank || '',
                points: problem.points || '',
                note: problem.note || '',
                // Reset Part1 and additional parts fields based on problem data
                ...problem?.parts?.reduce((acc, part, index) => {
                    acc[`part${index + 1}`] = part.name;
                    return acc;
                }, {}),
                // Reset additional parts to empty strings if there are less parts than before
                ...additionalParts.reduce((acc, part, index) => {
                    // Check if additional parts length is greater than existing parts length
                    if (additionalParts.length > problem?.parts?.length) {
                        acc[`part${index + 2}`] = ''; // Reset additional parts to empty string
                    }
                    return acc;
                }, {}),
                //reset last part1 value if no parts
                part1: problem?.parts?.at(0)?.name || ""
            });

            // Update additional parts state
            if (problem.parts && problem.parts.length > 1) {
                const additional = problem.parts.slice(1).map((part, index) => ({
                    id: Date.now() + index + 1,
                    name: part.name
                }));
                setAdditionalParts(additional);
            } else {
                setAdditionalParts([]);
            }

            // Manually trigger handleCategoryChange to update subcategories based on pre-populated category
            handleCategoryChange({ target: { value: problem.category } });
        } else {
            reset(); // Reset the form if no problem is selected
            setAdditionalParts([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, problem]);


    useEffect(() => {
        if (problem && problem.category) {
            const correspondingSubCategories = allSubCategories.find(category => category.categoryId === problem.category);
            setSubCategories(correspondingSubCategories ? correspondingSubCategories.subcategories : []);
        }
    }, [problem, allSubCategories]);

    const dispatch = useDispatch();


    const addPart = () => {
        setAdditionalParts([...additionalParts, { id: Date.now() }]);
    };

    const removePart = (id) => {
        setAdditionalParts(additionalParts.filter(part => part.id !== id));

        setValue(`part${additionalParts.length + 1}`, ''); // Reset the value of the last part
    };
    // console.log(problem)

    const handleModalOpen = (e, category_id, subCategory_id) => {
        e.stopPropagation();
        setIsEdit(false)
        // Reset form fields and additional parts state when the modal is opened
        // Reset dropdowns and input fields to their initial state
        const selectedCategoryObj = allCategories.find(category => category.id === category_id);
        setSelectedCategory(selectedCategoryObj);

        // Find corresponding subcategories
        const correspondingSubCategories = allSubCategories.find(category => category.categoryId === category_id);
        setSubCategories(correspondingSubCategories ? correspondingSubCategories.subcategories : []);

        let rank = allProblems?.find(cat => cat.id === category_id)
            .subcategories.find(subCat => subCat.id === subCategory_id)
            .problems.reduce((max, prob) => prob.rank > max ? prob.rank : max, 0) + 1

        setValue('category', category_id); // Reset category dropdown
        setValue('subCategory', subCategory_id); // Reset subCategory dropdown
        setValue('name', ''); // Reset name field
        setValue('rank', rank); // Reset rank field
        setValue('note', ''); // Reset rank field
        setValue('points', ''); // Reset points field
        setValue('part1', ''); // Reset part1 field
        setValue('part2', ''); // Reset part2 field
        setValue('part3', ''); // Reset part3 field
        setValue('part4', ''); // Reset part4 field
        setValue('part5', ''); // Reset part5 field
        setValue('part6', ''); // Reset part6 field
        setHasIDE(1)


        setAdditionalParts([]); // Reset additional parts state
        setModalOpen(true);
    }

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setValue('category', selectedCategoryId); // Set the value using react-hook-form

        // Find the selected category object
        const selectedCategoryObj = allCategories.find(category => category.id === selectedCategoryId);
        setSelectedCategory(selectedCategoryObj);

        // Find corresponding subcategories
        const correspondingSubCategories = allSubCategories.find(category => category.categoryId === selectedCategoryId);
        setSubCategories(correspondingSubCategories ? correspondingSubCategories.subcategories : []);
    };


    const onSubmit = async (data) => {
        if (isEdit && isEdit) {

            const parts = [];

            let lastNonEmptyIndex = null;
            for (const key in data) {
                if (key.startsWith("part")) {
                    if (data[key]?.trim() !== "") {
                        lastNonEmptyIndex = key;
                    }
                }
            }

            // Delete empty values after the last non-empty value
            if (lastNonEmptyIndex !== null) {
                let foundNonEmptyValue = false;
                for (const key in data) {
                    if (key === lastNonEmptyIndex) {
                        foundNonEmptyValue = true;
                    }

                    if (foundNonEmptyValue && key.startsWith("part") && data[key]?.trim() === "") {
                        delete data[key];
                    }
                }
            }

            if (lastNonEmptyIndex !== null) {
                for (const key in data) {
                    // Check if the key starts with "part" and the value is not an empty string
                    if (key.startsWith("part")) {
                        // Push the non-empty value into the parts array
                        parts.push(data[key]);
                    }

                    // parts are being submitted even if deleted from additional parts 
                    if (additionalParts.length + 1 === parts.length) {
                        break
                    }
                }

            }
            const { category, subCategory, name, rank, points, note } = data

            // Check if the parts array is the same as the problem parts array
            const partChanged = parts.length === problem.parts.length && parts.every((part, index) => part === problem.parts[index].name);
            const check = hasIDE ? 1 : 0
            dispatch(updateProblem(encodeURIComponent(problem.problem_id), category, subCategory, name, rank, points, parts.length, parts, check, setModalOpen, !partChanged, note, isProd))

            setAdditionalParts([]);



        } else {
            const parts = [];


            for (const key in data) {
                // Check if the key starts with "part" and the value is not an empty string
                if (key.startsWith("part") && data[key] !== undefined && data[key].trim() !== "") {
                    // Push the non-empty value into the parts array
                    parts.push(data[key]);
                }
                // parts are being submitted even if deleted from additional parts 
                if (additionalParts.length + 1 === parts.length) {
                    break
                }

            }

            const { category, subCategory, name, rank, points, note } = data

            const check = hasIDE ? 1 : 0
            dispatch(addProblem(category, subCategory, name, rank, points, parts.length, parts, check, setModalOpen, note, isProd))
            setAdditionalParts([]);
        }
    }

    useEffect(() => {
        if (problem.subCategory) {
            setValue('subCategory', problem.subCategory)
        }
    }, [problem.subCategory, setValue])

    /* const handleAddButtonClick = (e) => {
        resetFormFields(e); // Reset all form fields when the "Add" button is clicked
        setIsEdit(false)
        handleModalOpen(e); // Open the modal
    };
    
    const resetFormFields = (e) => {
        setValue('category', ''); // Reset category dropdown
        setValue('subCategory', ''); // Reset subCategory dropdown
        setValue('name', ''); // Reset name field
        setValue('rank', ''); // Reset rank field
        setValue('points', ''); // Reset points field
        setValue('part1', ''); // Reset part1 field
        setAdditionalParts([]); // Reset additional parts state
    }; */


    const [totalProblemCount, setTotalProblemCount] = useState(0)
    useEffect(() => {
        let totalProblems = 0;
        allProblems.forEach(cat => {
            cat.subcategories.forEach(subcat => {
                if (subcat.type === "study") {
                    totalProblems += subcat.problems.length;
                }
            });
        });
        setTotalProblemCount(totalProblems)
    }, [allProblems])

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                        <div className='flex mb-8 flex-row justify-between'>
                            {/*  <button onClick={handleAddButtonClick} className='btn-brand-2'>Add</button> */}
                            {/*  <div className=" relative w-fit">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-white placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500"
                                    type="search" placeholder="Search…"
                                // value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="absolute inset-0 right-auto group" aria-label="Search">
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div> */}
                            <p className=' text-lg mt-2 font-bold text-gray-800 dark:text-zinc-300'>Number of Problems : {totalProblemCount}</p>
                        </div>
                        <div className='flex gap-y-2 flex-col justify-start'>

                            {allProblems && allProblems.length > 0 ? (
                                allProblems.map((categories) => (
                                    <div key={categories.id}>
                                        <ContentAccordion
                                            title={categories?.name}
                                            numberOfProblems={categories.studyProblemCount}
                                        >
                                            <p className='font-bold text-gray-800 dark:text-zinc-300'>Study Problem Count: {categories.studyProblemCount}</p>
                                            <p className='font-bold text-gray-800 dark:text-zinc-300'>Contest Problem Count: {categories.contestProblemCount}</p>

                                            {categories?.subcategories?.map((subCategories) => (
                                                <div key={subCategories.id} className='p-2 '>
                                                    <ContentAccordion
                                                        title={subCategories.name}>
                                                        <div className='flex flex-col p-4 '>
                                                            <div className='flex'>
                                                                <p className='font-bold text-gray-800 dark:text-zinc-300'>Problem count: {subCategories.problemCount}</p>
                                                                <button onClick={(e) => handleModalOpen(e, categories.id, subCategories.id)} className='bg-brand text-white rounded-lg p-1 ml-auto mb-5'>
                                                                    <Plus size={18} />
                                                                </button>
                                                            </div>
                                                            <CategoryTable setIsEdit={setIsEdit} page='PB' topics={subCategories.problems} setModalOpen={setModalOpen} modalOpen={modalOpen} />

                                                        </div>

                                                    </ContentAccordion>
                                                </div>
                                            ))}

                                        </ContentAccordion>
                                    </div>
                                ))) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Problems to show
                                </div>
                            )}
                        </div>
                        <CategoryModal title='Add Problem' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                <div className='flex items-center gap-x-4'>
                                    <input
                                        type="checkbox"
                                        className='form-checkbox'
                                        checked={hasIDE}
                                        onChange={() => setHasIDE(!hasIDE)}
                                    />
                                    <label htmlFor="showExample3">Has IDE</label>
                                </div>
                                <div className="flex justify-between mt-8 gap-2">
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Category</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        {...field}
                                                        onChange={handleCategoryChange}
                                                        disabled={true}
                                                        className="form-select w-full text-gray-300"
                                                    >
                                                        <option value="">Select Category...</option>
                                                        {allCategories?.length > 0 && (
                                                            allCategories?.map((category) => (
                                                                <option key={category?.id} value={category?.id}>{category?.name}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Sub Category</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="subCategory"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        disabled={true}
                                                        {...field}
                                                        className="form-select w-full text-gray-300"
                                                    >
                                                        <option value="">Select Sub Category...</option>
                                                        {subCategories?.length > 0 && (
                                                            subCategories?.map((subC) => (
                                                                <option key={subC?.id} value={subC?.id}>{subC?.name}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='name'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex justify-between gap-x-4 mt-6">
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Rank</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="rank"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="rank"
                                                        type="number"
                                                        name="rank"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='rank'
                                                        {...field}
                                                    />
                                                )}

                                            />

                                            {isSubmitted && errors.rank && <p className="text-red-600 text-sm italic">{errors.rank.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Points</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="points"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="points"
                                                        type="number"
                                                        name="points"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='points'
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            {isSubmitted && errors.points && <p className="text-red-600 text-sm italic">{errors.points.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Note</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="note"
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        id="note"
                                                        type="text"
                                                        rows={4}
                                                        name="note"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='Note'
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            {isSubmitted && errors.note && <p className="text-red-600 text-sm italic">{errors.note.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Part 1</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="part1"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="part1"
                                                        type="text"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='part1'
                                                        {...field}
                                                    />
                                                )}

                                            />
                                        </div>
                                    </div>
                                </div>
                                {additionalParts.map((part, index) => (
                                    <div key={part.id} className='mt-4'>
                                        <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                            <div className='flex justify-between items-center'>
                                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Part {index + 2}</span>
                                                {index === additionalParts.length - 1 && (
                                                    <button type="button" className='mr-4' onClick={(e) => { e.stopPropagation(); removePart(part.id) }}>
                                                        <BadgeX className='stroke-red-600' size={18} />
                                                    </button>
                                                )}
                                            </div>
                                            <div className="w-full px-3">
                                                {/* Your input field for additional parts */}
                                                <Controller
                                                    name={`part${index + 2}`}
                                                    control={control}
                                                    defaultValue={part.name} // Set the default value
                                                    render={({ field }) => (
                                                        <input
                                                            id={`part${index + 2}`}
                                                            type="text"
                                                            className="form-input w-full text-gray-300"
                                                            placeholder={`part${index + 2}`}
                                                            {...field}
                                                        />
                                                    )}

                                                />

                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className='mt-6'>
                                    <button type="button" onClick={addPart} className='btn bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'>
                                        <Plus size={18} className='stroke-zinc-600' />
                                    </button>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Problems