import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { generateCodeSection } from '../../utils/Utils';
import Prism from "prismjs";
import "prismjs/components/prism-java";
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-python";
import { ClipboardCopy } from 'lucide-react';
import toast from 'react-hot-toast';
const CreateEditor = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const [numberOfLanguages, setNumberOfLanguages] = useState(1);
    const [codeSectionString, setCodeSectionString] = useState(``);
    const { control, handleSubmit, setValue, reset } = useForm();

    const onSubmit = (data, e) => {
        const formattedData = [];

        console.log(data)
        for (let i = 1; i <= numberOfLanguages; i++) {
            const name = data[`name${i}`];
            const content = data[`content${i}`];
            formattedData.push({ name, content });
        }
        setCodeSectionString(generateCodeSection(formattedData))
        reset({ name1: "", name2: "", name3: "", name4: "", content1: "", content2: "", content3: "", content4: "" });
    };

    const handleNumberOfLanguagesChange = (event) => {
        setNumberOfLanguages(parseInt(event.target.value));

        if (parseInt(event.target.value) === 4) {
            setValue("name1", "Cpp");
            setValue("name2", "Java");
            setValue("name3", "Python");
            setValue("name4", "Javascript");
        }
    };

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    useEffect(() => {
        const codeSections = document.querySelectorAll('.code-section');
        // Get last selected language from localStorage or set default value
        let lastSelectedLanguage = localStorage.getItem('lastSelectedLanguage') || 'cpp';

        codeSections.forEach((section, sectionIndex) => {
            const codeTabs = section.querySelectorAll('.code-tab');
            const codeBlocks = section.querySelectorAll('.code-block');
            const copyBtn = section.querySelector('.copy-btn');

            codeTabs.forEach((tab) => {
                tab.addEventListener('click', () => {
                    codeTabs.forEach((t) => {
                        t.classList.remove('dsa_article_code_active');
                    });
                    tab.classList.add('dsa_article_code_active');

                    const lang = tab.dataset.lang;
                    const activeBlock = section.querySelector(`.code-block[data-lang="${lang}"]`);
                    codeBlocks.forEach((block) => {
                        if (block === activeBlock) {
                            block.classList.add('dsa_article_code_active');
                        } else {
                            block.classList.remove('dsa_article_code_active');
                        }
                    });

                    // Update last selected language for all sections and store in localStorage
                    lastSelectedLanguage = lang;
                    localStorage.setItem('lastSelectedLanguage', lang);
                    codeSections.forEach((otherSection, otherIndex) => {
                        if (otherIndex !== sectionIndex) {
                            const otherTabs = otherSection.querySelectorAll('.code-tab');
                            otherTabs.forEach((otherTab) => {
                                if (otherTab.dataset.lang === lang) {
                                    otherTab.classList.add('dsa_article_code_active');
                                } else {
                                    otherTab.classList.remove('dsa_article_code_active');
                                }
                            });
                            const otherActiveBlock = otherSection.querySelector(`.code-block[data-lang="${lang}"]`);
                            const otherCodeBlocks = otherSection.querySelectorAll('.code-block');
                            otherCodeBlocks.forEach((block) => {
                                if (block === otherActiveBlock) {
                                    block.classList.add('dsa_article_code_active');
                                } else {
                                    block.classList.remove('dsa_article_code_active');
                                }
                            });
                        }
                    });
                });
            });

            // Handle copy button click event
            copyBtn.addEventListener('click', () => {
                const activeBlock = section.querySelector('.code-block.dsa_article_code_active code');
                navigator.clipboard.writeText(activeBlock.innerText);
                copyBtn.classList.add('dsa_article_code_active');
                setTimeout(() => {
                    copyBtn.classList.remove('dsa_article_code_active');
                }, 1000);
            });
        });

        // Set initial state of the tabs
        codeSections.forEach((section) => {
            const codeTabs = section.querySelectorAll('.code-tab');
            const codeBlocks = section.querySelectorAll('.code-block');

            codeTabs.forEach((tab) => {
                if (tab.dataset.lang === lastSelectedLanguage) {
                    tab.classList.add('dsa_article_code_active');
                } else {
                    tab.classList.remove('dsa_article_code_active');
                }
            });

            codeBlocks.forEach((block) => {
                if (block.dataset.lang === lastSelectedLanguage) {
                    block.classList.add('dsa_article_code_active');
                } else {
                    block.classList.remove('dsa_article_code_active');
                }
            });
        });
    })

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(codeSectionString);
            toast.success("Copied Successfully")
        } catch (error) {
            toast.error("Failed to copy clipboard")
        }
    };

    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-[#f5f5f5]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        <form className='w-3/5 mx-auto bg-white dark:bg-dark_50 p-6 rounded-lg border border-zinc-300 dark:border-zinc-800' onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-col gap-y-1'>
                                <label htmlFor="numberOfLanguages">Select Number of Languages:</label>
                                <select
                                    id="numberOfLanguages"
                                    name="numberOfLanguages"
                                    className='form-select'
                                    value={numberOfLanguages}
                                    onChange={handleNumberOfLanguagesChange}
                                >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                            <div className='mt-4 mb-8'>
                                {[...Array(numberOfLanguages)].map((_, index) => {
                                    const nameFieldName = `name${index + 1}`;
                                    const contentFieldName = `content${index + 1}`;

                                    return (
                                        <div key={index} className='flex flex-col gap-y-4'>
                                            <div className='flex flex-col gap-y-1 mt-8'>
                                                <label htmlFor={nameFieldName}>Name {index + 1}:</label>
                                                <Controller
                                                    name={nameFieldName}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-select w-full text-gray-300"
                                                        >
                                                            <option value="">Select Language...</option>
                                                            <option value="Cpp">Cpp</option>
                                                            <option value="Java">Java</option>
                                                            <option value="Python">Python</option>
                                                            <option value="Javascript">Javascript</option>
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                            <div className='flex flex-col gap-y-1'>

                                                <label htmlFor={contentFieldName}>Content {index + 1}:</label>
                                                <Controller
                                                    name={contentFieldName}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => <textarea rows={3} className='form-textarea' {...field} />}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <button className='btn w-full' type="submit">Submit</button>
                        </form>

                        {codeSectionString && (
                            <div className='mt-8 mx-auto flex justify-center items-center w-3/5'>
                                <div className='flex flex-col gap-y-4 justify-between w-full p-2 rounded-lg border border-zinc-400 dark:border-zinc-800 dark:bg-dark_50 bg-[#f5f5f5]'>
                                    <button onClick={copyToClipboard}>
                                        <ClipboardCopy className='hover:stroke-brand' />
                                    </button>
                                    <span className='w-full max-w-11/12'>
                                        {codeSectionString}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: codeSectionString }} />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default CreateEditor