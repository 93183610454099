import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { Controller, useForm } from 'react-hook-form';
import { addDeletedProb, } from '../../services/operations/syllabus';

const Problems = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);


    const { allDeletedProblems, allCategories, allSubCategories, problem } = useSelector((state) => state.syllabus)
    const { isProd } = useSelector((state) => state.auth)
    const { handleSubmit, control, setValue, reset } = useForm();
    // console.log(problem)

    useEffect(() => {
        if (problem) {

            reset({
                category: problem.category || '',
                subCategory: problem.subCategory || '',
                problem_id:problem.problem_id || '',
            });

            // Manually trigger handleCategoryChange to update subcategories based on pre-populated category
            handleCategoryChange({ target: { value: problem.category } });
        } else {
            reset(); // Reset the form if no problem is selected
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, problem]);


    useEffect(() => {
        if (problem && problem.category) {
            const correspondingSubCategories = allSubCategories.find(category => category.categoryId === problem.category);
            setSubCategories(correspondingSubCategories ? correspondingSubCategories.subcategories : []);
        }
    }, [problem, allSubCategories]);

    const dispatch = useDispatch();



    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setValue('category', selectedCategoryId); // Set the value using react-hook-form

        // Find the selected category object
        const selectedCategoryObj = allCategories.find(category => category.id === selectedCategoryId);
        setSelectedCategory(selectedCategoryObj);

        // Find corresponding subcategories
        const correspondingSubCategories = allSubCategories.find(category => category.categoryId === selectedCategoryId);
        setSubCategories(correspondingSubCategories ? correspondingSubCategories.subcategories : []);
    };


    const onSubmit = async (data) => {
        const {problem_id,category,subCategory}=data
        dispatch(addDeletedProb(problem_id,category,subCategory,setModalOpen, isProd))
    }

    useEffect(() => {
        if (problem.subCategory) {
            setValue('subCategory', problem.subCategory)
        }
    }, [problem.subCategory, setValue])

 
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">

                        <div className='flex mt-8 gap-y-2 flex-col justify-start'>

                            {allDeletedProblems && allDeletedProblems.length > 0 ? (
                                <CategoryTable page='Deleted_PB' topics={allDeletedProblems} setModalOpen={setModalOpen} modalOpen={modalOpen} />
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Problems to show
                                </div>
                            )}
                        </div>
                        <CategoryModal title='Add Problem' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                <div className="flex justify-between mt-8 gap-2">
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Category</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        {...field}
                                                        onChange={handleCategoryChange}
                                                        className="form-select w-full text-gray-300"
                                                    >
                                                        <option value="">Select Category...</option>
                                                        {allCategories?.length > 0 && (
                                                            allCategories?.map((category) => (
                                                                <option key={category?.id} value={category?.id}>{category?.name}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Sub Category</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="subCategory"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        {...field}
                                                        className="form-select w-full text-gray-300"
                                                    >
                                                        <option value="">Select Sub Category...</option>
                                                        {subCategories?.length > 0 && (
                                                            subCategories?.map((subC) => (
                                                                <option key={subC?.id} value={subC?.id}>{subC?.name}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Problems