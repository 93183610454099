import React, { useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import { ClipboardCopy } from 'lucide-react';
import toast from 'react-hot-toast';

const UploadTextFile = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');
    const [fileLink, setFileLink] = useState('');

    const handleFileUpload = async (e) => {
        e.preventDefault();

        if (!file) {
            console.error('No file selected');
            return;
        }

        let formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL_PROD}/uploadTextFile`,
                formData,
                { headers: { 'Content-type': 'multipart/form-data' }, withCredentials: true, }
            );

            if (response.data.success) {
                setFileLink(response.data.link);
                setFileName('');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log(selectedFile); // Log the selected file to verify if it's correctly obtained
        setFile(selectedFile);
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(fileLink);
            toast.success("File Link Copied Successfully");
        } catch (error) {
            toast.error("Failed to copy file link to clipboard");
        }
    };

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex items-start justify-center'>
                            <form onSubmit={handleFileUpload} encType='multipart/form-data' className='flex flex-col gap-x-4 w-1/2'>
                                <label
                                    htmlFor='dropzone-file'
                                    className='flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 dark:border-zinc-600 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-dark_50 dark:hover:bg-bray-800'
                                >
                                    <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                                        <svg
                                            className='w-4 h-4 mb-3 text-gray-400'
                                            fill='none'
                                            stroke='currentColor'
                                            viewBox='0 0 24 24'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                            ></path>
                                        </svg>
                                        <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                            <span className='font-semibold'>Click to upload</span> or drag and drop
                                        </p>
                                        <p className='text-xs text-gray-500 dark:text-gray-400'>TXT files only (Max. 1mb)</p>
                                    </div>
                                    <input
                                        id='dropzone-file'
                                        className='hidden'
                                        type='file'
                                        name='file'
                                        accept='.txt'
                                        onChange={handleFileChange}
                                    />
                                </label>

                                <input
                                    type="text"
                                    name="fileName"
                                    id="fileName"
                                    className='form-input mt-8'
                                    placeholder='File Name'
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                />

                                <button
                                    className='mt-8 bg-brand_50 px-4 py-2 rounded-md hover:bg-brand text-white w-fit'
                                    type='submit'
                                >
                                    Upload File
                                </button>
                            </form>
                        </div>
                        {fileLink && (
                            <div className='mt-8 mx-auto flex justify-center items-center'>
                                <div className='flex justify-between w-1/2 p-2 rounded-lg border border-zinc-400 dark:border-zinc-800 dark:bg-dark_50 bg-[#f5f5f5]'>
                                    <span className='w-full max-w-11/12'>
                                        {fileLink}
                                    </span>
                                    <button onClick={copyToClipboard}>
                                        <ClipboardCopy className='hover:stroke-brand' />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default UploadTextFile;
