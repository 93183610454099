import { setIsProd } from "../../slices/authSlice"
import { setAllCategories, setAllDeletedProblems, setAllProblems, setAllSubCategories, setLoading, setProblem, setProblemSetter, setDislikedProblems } from "../../slices/syllabusSlice"
import { mergeObjects } from "../../utils/Utils"
import { categoriesEndpoints, problemSetterEndpoints, problemsEndpoints, subCategoriesEndpoints, clearCacheEndpoints, problemSetterEndpointsDev, categoriesEndpointsDev, subCategoriesEndpointsDev, problemsEndpointsDev } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'



const { ADD_CAT, UPDATE_CAT, DEL_CAT } = categoriesEndpoints

const { GET_ALL_CAT_DEV, ADD_CAT_DEV, UPDATE_CAT_DEV, DEL_CAT_DEV } = categoriesEndpointsDev

const { ADD_SUB_CAT, UPDATE_SUB_CAT, DEL_SUB_CAT } = subCategoriesEndpoints

const { GET_ALL_SUB_CAT_DEV, ADD_SUB_CAT_DEV, UPDATE_SUB_CAT_DEV, DEL_SUB_CAT_DEV } = subCategoriesEndpointsDev

const { ADD_PROBLEM, DEL_PROBLEM, UPDATE_PROBLEM, ADD_PROBLEM_VIDEO_THUMB, ADD_PROBLEM_EDITORIALS, ADD_DELETED_PROBLEM, DEL_DELETED_PROBLEM, } = problemsEndpoints

const { GET_ALL_PROBLEM_DEV, ADD_PROBLEM_DEV, DEL_PROBLEM_DEV, UPDATE_PROBLEM_DEV, ADD_PROBLEM_VIDEO_THUMB_DEV, ADD_PROBLEM_EDITORIALS_DEV, GET_PROBLEM_DEV, GET_DELETED_PROBLEM_DEV, ADD_DELETED_PROBLEM_DEV, DEL_DELETED_PROBLEM_DEV, GET_DISLIKED_PROBLEMS_DEV } = problemsEndpointsDev

const { UPDATE_PROBLEM_SETTER_DATA  } = problemSetterEndpoints

const { UPDATE_PROBLEM_SETTER_DATA_DEV, GET_PROBLEM_DETAILS_DEV } = problemSetterEndpointsDev

const { CLEAR_CACHE } = clearCacheEndpoints
const { CLEAR_CACHE_JUDGE, CLEAR_CACHE_JUDGETWO } = clearCacheEndpoints

export function getAllCategories() {
    return async (dispatch, getState) => {
        try {
            // if ((getState().syllabus.allCategories)?.length === 0) {
            const response = await apiConnector("GET", GET_ALL_CAT_DEV)
            dispatch(setAllCategories(response.data.allCategories))
            // }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function addCategory(name, rank, percentage, setModalOpen, isProd) {
    return async (dispatch, getState) => {
        try {
            if (isProd) {
                const response = await apiConnector("POST", ADD_CAT, { name, rank, percentage })
                const response2 = await apiConnector("POST", ADD_CAT_DEV, { name, rank, percentage })
                if (response.data.success && response2.data.success) {
                    setModalOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("POST", ADD_CAT_DEV, { name, rank, percentage })
                if (response.data.success) {
                    setModalOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function updateCategory(id, name, rank, percentage, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("PUT", UPDATE_CAT + `${id}`, { name, rank, percentage })
                const response2 = await apiConnector("PUT", UPDATE_CAT_DEV + `${id}`, { name, rank, percentage })
                if (response.data.success && response2.data.success) {
                    setModalOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", UPDATE_CAT_DEV + `${id}`, { name, rank, percentage })
                if (response.data.success) {
                    setModalOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())

                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function deleteCategory(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("DELETE", DEL_CAT + `${id}`)
                const response2 = await apiConnector("DELETE", DEL_CAT_DEV + `${id}`)
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("DELETE", DEL_CAT_DEV + `${id}`)
                if (response.data.success) {
                    dispatch(getAllCategories())
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)

                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function addSubCategory(category_id, name, rank, type, setSubCategoryOpen, isProd) {
    return async (dispatch, getState) => {
        try {
            if (isProd) {
                const response = await apiConnector("POST", ADD_SUB_CAT + `${category_id}`, { name, rank, type })
                const response2 = await apiConnector("POST", ADD_SUB_CAT_DEV, { category_id, name, rank, type })
                if (response.data.success && response2.data.success) {
                    setSubCategoryOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {

                const response = await apiConnector("POST", ADD_SUB_CAT_DEV, { category_id, name, rank, type })
                if (response.data.success) {
                    setSubCategoryOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function updateSubCategory(id, categoryId, name, type, rank, setSubCategoryOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("PUT", UPDATE_SUB_CAT + `${id}`, { name, type, rank, categoryId })
                const response2 = await apiConnector("PUT", UPDATE_SUB_CAT_DEV + `${id}`, { name, type, rank, categoryId })
                if (response.data.success && response2.data.success) {
                    setSubCategoryOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", UPDATE_SUB_CAT_DEV + `${id}`, { name, type, rank, categoryId })
                if (response.data.success) {
                    setSubCategoryOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                    
                }   
            }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function deleteSubCategory(id, categoryId, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("DELETE", DEL_SUB_CAT + `${id}`, { categoryId })
                const response2 = await apiConnector("DELETE", DEL_SUB_CAT_DEV + `${id}`, { categoryId })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)
                    dispatch(getAllSubCategories())
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("DELETE", DEL_SUB_CAT_DEV + `${id}`, { categoryId })
                if (response.data.success) {
                    dispatch(getAllSubCategories())
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)
                    dispatch(getAllProblems())
                    
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function getAllSubCategories() {
    return async (dispatch, getState) => {
        try {
            // if ((getState().syllabus.allSubCategories)?.length === 0) {
            const response = await apiConnector("GET", GET_ALL_SUB_CAT_DEV)
            dispatch(setAllSubCategories(response.data.allSubcategory))
            // }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function getAllProblems() {
    return async (dispatch, getState) => {
        try {
            // if ((getState().syllabus.allProblems)?.length === 0) {
            const response = await apiConnector("GET", GET_ALL_PROBLEM_DEV)
            // console.log(response.data)
            dispatch(setAllProblems(response.data.allProblems))
            // }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function addProblem(category_id, subcategory_id, name, rank, points, num_parts, parts_name, hasIDE, setModalOpen, note, isProd) {
    return async (dispatch, getState) => {
        try {
            if (isProd) {
                const response = await apiConnector("POST", ADD_PROBLEM, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, hasIDE, note })
                const response2 = await apiConnector("POST", ADD_PROBLEM_DEV, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, hasIDE, note })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    setModalOpen(false)
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("POST", ADD_PROBLEM_DEV, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, hasIDE, note })
                if (response.data.success) {
                    setModalOpen(false)
                    toast.success(response.data.message)
                    dispatch(getAllProblems())
                }
            }

        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }
}

export function updateProblem(id, category_id, subcategory_id, name, rank, points, num_parts, parts_name, hasIDE, setModalOpen, changedParts, note, isProd) {
    return async (dispatch, getState) => {
        try {
            if (isProd) {
                const response = await apiConnector("PUT", UPDATE_PROBLEM + `${id}`, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, changedParts, hasIDE, note })
                const response2 = await apiConnector("PUT", UPDATE_PROBLEM_DEV + `${id}`, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, changedParts, hasIDE, note })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    dispatch(getAllProblems())
                    if (setModalOpen) {
                        setModalOpen(false)
                    }
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", UPDATE_PROBLEM_DEV + `${id}`, { category_id, subcategory_id, name, rank, points, num_parts, parts_name, changedParts, hasIDE, note })
                if (response.data.success) {
                    toast.success(response.data.message)
                    dispatch(getAllProblems())
                    if (setModalOpen) {
                        setModalOpen(false)
                    }
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function deleteProblem(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("DELETE", DEL_PROBLEM + `${id}`, {})
                const response2 = await apiConnector("DELETE", DEL_PROBLEM_DEV + `${id}`)
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)
                    dispatch(getAllProblems())
                    dispatch(getAllDeletedProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("DELETE", DEL_PROBLEM_DEV + `${id}`)
                if (response.data.success) {
                    dispatch(getAllProblems())
                    dispatch(getAllDeletedProblems())
                    toast.success(response.data.message)
                    setModalDeleteOpen(false)
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function addVideoAndThumb(videoData, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("PUT", ADD_PROBLEM_VIDEO_THUMB, { videoData })
                const response2 = await apiConnector("PUT", ADD_PROBLEM_VIDEO_THUMB_DEV, { videoData })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    setModalOpen(false)
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", ADD_PROBLEM_VIDEO_THUMB_DEV, { videoData })
                if (response.data.success) {
                    setModalOpen(false)
                    dispatch(getAllProblems())
                    toast.success(response.data.message)
                }
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function addEditorials(editorialsData, setModalOpen, isProd) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            if (isProd) {
                const response = await apiConnector("PUT", ADD_PROBLEM_EDITORIALS, { editorialsData })
                const response2 = await apiConnector("PUT", ADD_PROBLEM_EDITORIALS_DEV, { editorialsData })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message)
                    if (setModalOpen) {
                        setModalOpen(false)
                    }
                    dispatch(getAllProblems())
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", ADD_PROBLEM_EDITORIALS_DEV, { editorialsData })
                // console.log(response.data)
                if (response.data.success) {
                    if (setModalOpen) {
                        setModalOpen(false)
                    }
                    dispatch(getAllProblems())
                    toast.success(response.data.message)
                }
            }

            
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    }
}
export function getProblemDetails(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PROBLEM_DETAILS_DEV + `${id}`)
            if (response.data.success) {
                dispatch(setProblemSetter(mergeObjects(response.data.data, response.data.logicalData)))
            }

        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function saveProblemSetter(id, problemData, isProd) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            if (isProd) {
                const response = await apiConnector("PUT", UPDATE_PROBLEM_SETTER_DATA, { id, problemData })
                const response2 = await apiConnector("PUT", UPDATE_PROBLEM_SETTER_DATA_DEV, { id, problemData })
                if (response.data.success && response2.data.success) {
                    toast.success("Saved Successfully")
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("PUT", UPDATE_PROBLEM_SETTER_DATA_DEV, { id, problemData })
                if (response.data.success) {
                    toast.success("Saved Successfully")
                }
            }
        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    }
}
export function getProblem(id) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PROBLEM_DEV + `${id}`)
            dispatch(setProblem(response.data.result))
        } catch (error) {
            // console.log("Save Draft ERROR............", error)
        }
    }
}

export function getAllDeletedProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_DELETED_PROBLEM_DEV)
            dispatch(setAllDeletedProblems(response.data))
        } catch (error) {
            // console.log("delete problem error", error)
        }
    }
}

export function addDeletedProb(problem_id, category, subcategory, setModalOpen, isProd) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            if (isProd) {
                await apiConnector("POST", ADD_DELETED_PROBLEM, { problem_id, category, subcategory })
                await apiConnector("POST", ADD_DELETED_PROBLEM_DEV, { problem_id, category, subcategory })
                dispatch(getAllProblems())
                dispatch(getAllDeletedProblems())
                toast.success("problem added")
                setModalOpen(false)
                dispatch(setIsProd(false))
            } else {
                await apiConnector("POST", ADD_DELETED_PROBLEM_DEV, { problem_id, category, subcategory })
                dispatch(getAllProblems())
                dispatch(getAllDeletedProblems())
                toast.success("problem added")
                setModalOpen(false)
            }

        } catch (error) {
            // console.log("delete problem error", error)
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    }
}

export function deleteDeletedProblem(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector("DELETE", DEL_DELETED_PROBLEM + `${id}`)
                const response2 = await apiConnector("DELETE", DEL_DELETED_PROBLEM_DEV + `${id}`)
                if (response2.data.success && response.data.success) {
                    dispatch(getAllDeletedProblems())
                    toast.success("problem deleted from dev")
                    setModalDeleteOpen(false)
                }
                dispatch(setIsProd(false))
            } else {
                const response = await apiConnector("DELETE", DEL_DELETED_PROBLEM_DEV + `${id}`)
                if (response.data.success) {
                    dispatch(getAllDeletedProblems())
                    toast.success("problem deleted")
                    setModalDeleteOpen(false)
                }   
            }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function clearCache() {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            const response = await apiConnector("GET", CLEAR_CACHE)
            if (response.data.success) {
                toast.success(response.data.message)
            }
            dispatch(getAllCategories())
            dispatch(getAllSubCategories())
            dispatch(getAllProblems())

        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    }
}
export function clearCacheJudge() {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            const response = await apiConnector("GET", CLEAR_CACHE_JUDGE)
            const response2 = await apiConnector("GET", CLEAR_CACHE_JUDGETWO)
            if (response.data.success && response2.data.success) {
                toast.success(response.data.message)
            }

        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    }
}

export function getDislikedProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_DISLIKED_PROBLEMS_DEV)
            if (response.data.success) {
                dispatch(setDislikedProblems(response.data.result))
            } else {
                toast.error(response.data.message || "An error occurred");
            }
        } catch (error) {
            console.log(error)
        }
    }
}