import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification: {},
    allNotifications: [],
};

const notificationSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        setNotification(state, value) {
            state.notification = value.payload;
        },
        setAllNotifications(state, value) {
            state.allNotifications = value.payload;
        },
    },
});

export const { setNotification, setAllNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;