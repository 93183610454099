import toast from "react-hot-toast"
import { setAllUsers, setSignInData, setToken } from "../../slices/authSlice"
import { deleteCookie, setObjectInCookie } from "../../utils/Utils"
import { authEndpoints } from "../api"
import { apiConnector } from "../apiConnector"


const { LOGIN_IN, LOGOUT_IN, SET_USER_IN, SET_USER_IN_DEV,DELETE_USER_DEV, ALL_USERS, DELETE_USER } = authEndpoints

export function getAllUsers() {
    return async (dispatch, getState) => {
        try {
            // if (getState().auth.allUsers.length === 0) {
            const response = await apiConnector("GET", ALL_USERS)
            dispatch(setAllUsers(response.data.all_users))
            // }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function deleteUser(user_id) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("DELETE", DELETE_USER + `${user_id}`)
            const response2 = await apiConnector("DELETE", DELETE_USER_DEV + `${user_id}`)
            if (response.data.success && response2.data.success) {
                dispatch(getAllUsers())
                toast.success(response.data.message)
            }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}
export function login(userName, password, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGIN_IN, { userName, password })
            if (response.data.success && response.data.userData) {
                setObjectInCookie('userDataPremium', response.data.userData, 7)
                dispatch(setSignInData(response.data.userData))
                dispatch(setToken(response.data.token))
                toast.success("Login Successful")
                window.location.reload()
                navigate(`/admin`)
            }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message)
        }
    }
}

export function setUser(userName, password, role, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", SET_USER_IN, { userName, password, role })
            const response2 = await apiConnector("POST", SET_USER_IN_DEV, { userName, password, role })
            if (response.data.success && response2.data.success) {
                toast.success(`${userName} Added Successfully`)
                setModalOpen(false)
                dispatch(getAllUsers())
            }
        } catch (error) {
            if (error && error.response && error.response.status === 403) {
                toast.error(`${userName} Already Exists`)
            }
        }
    }
}

export function logout(navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGOUT_IN)

            if (response.data.success) {
                deleteCookie('userDataPremium')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("takeuforward_premium_dashboard")
                toast.success("Logout Successful")
                window.location.reload()
                navigate(`/`)
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Session Expired")
                deleteCookie('userDataPremium')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("takeuforward_premium_dashboard")
                window.location.reload()
            }
        }
    }
}

