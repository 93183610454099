import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSubCategory } from '../../../slices/syllabusSlice'
import { deleteSubCategory } from '../../../services/operations/syllabus'
import CategoryModal from '../../Shared/Modals/CategoryModal'

const SubCategoryTableItem = (props) => {

    const { setModalOpen, setIsEdit } = props

    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

    const {isProd} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const handleSubCategoryEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            category: props?.category,
            categoryId: props?.categoryId,
            name: props?.name,
            rank: props?.rank,
            type: props?.type
        }
        dispatch(setSubCategory(data))
        setIsEdit(true)
        setModalOpen(true);
    }

    const handleSubCategoryDelete = () => {
        dispatch(deleteSubCategory(encodeURIComponent(props.id), props?.categoryId, setModalDeleteOpen, isProd))
    }
    const handleSubCategory = (e) => {
        e.stopPropagation();
        setModalDeleteOpen(true)
    }

    // console.log(props)
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.name}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.rank}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.type}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleSubCategory(e)} className='btn bg-red-500 text-white  border-gree-500'>Delete</button>
                        <button onClick={(e) => handleSubCategoryEdit(e)} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                    </div>
                </td>
            </tr>
            <CategoryModal title="Delete Subcategory" modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleSubCategoryDelete()}>Yes, Delete</button>
                </div>
            </CategoryModal>
        </tbody >
    )
}

export default SubCategoryTableItem