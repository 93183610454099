import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCategory, setProblem } from '../../../slices/syllabusSlice'
import { deleteCategory, deleteDeletedProblem, deleteProblem } from '../../../services/operations/syllabus'
import CategoryModal from '../../Shared/Modals/CategoryModal'

const CategoryTableItem = (props) => {

    const { setModalOpen, setIsEdit, setParts } = props
    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

    const {isProd} = useSelector((state) => state.auth)

    const handleCategoryEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            name: props.name,
            rank: props.rank,
            percentage: props?.percentage,

        }
        dispatch(setCategory(data))
        setIsEdit(true)
        setModalOpen(true);
    }

    const handleCat = (e) => {
        // dispatch(deleteCategory(props.id))
        e.stopPropagation();
        setModalDeleteOpen(true)
    }
    const handleProblem = (e) => {
        e.stopPropagation();
        setModalDeleteOpen(true)
    }
    const handleProblemDelete = (e) => {
        dispatch(deleteProblem(encodeURIComponent(props?.problem_id), setModalDeleteOpen, isProd))
    }

    const handleDeletedProblemDelete=(e)=>{
        dispatch(deleteDeletedProblem(encodeURIComponent(props.problem_id),setModalDeleteOpen, isProd))
    }

    const handleCatDelete = (e) => {
        dispatch(deleteCategory(encodeURIComponent(props.id), setModalDeleteOpen, isProd))

    }

    const handleProblemEdit = (e) => {
        e.stopPropagation()
        const data = {
            category: props?.cat_id,
            problem_id: props?.problem_id,
            subCategory: props?.subcatid,
            name: props?.problem_name,
            rank: props?.rank,
            hasIDE: props?.hasIDE,
            note: props?.note,
            points: props?.points,
            parts: props?.parts
        }
        setIsEdit(true)
        dispatch(setProblem(data))
        setModalOpen(true);
    }
    const handleVideoEdit = (e) => {
        e.stopPropagation()
        const data = {
            parts: props?.parts,
            problem_id: props?.problem_id ? props?.problem_id : props.id
        }
        setParts(data)
        setModalOpen(true);
    }
    const handleDeletedProblemAdd = (e) => { 
        e.stopPropagation()
        const data = {
            category: props?.cat_id,
            problem_id: props?.problem_id,
            subCategory: props?.subcatid,
        }
        dispatch(setProblem(data))
        setModalOpen(true);
    }

   

    const handleDeletedProblem = (e) => {
        e.stopPropagation();
        setModalDeleteOpen(true)
    }

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>

                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.name ? props?.name : props?.problem_name}
                    </div>
                </td>
                {props.page === 'Category' ? (
                    <>

                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.rank}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.percentage}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                <button onClick={(e) => handleCat(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                <button onClick={(e) => handleCategoryEdit(e)} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                            </div>
                        </td>
                    </>
                ) : (
                    <>


                        {props.page === 'PB' ? (
                            <>
                                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                        {props?.rank}
                                    </div>
                                </td>
                                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">

                                        <button onClick={handleProblemEdit} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                                        <button onClick={(e) => handleProblem(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                    </div>
                                </td>
                            </>
                        ) : (
                            <>
                                {props.page === 'Deleted_PB' ? (
                                    <>
                                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                {props?.rank}
                                            </div>
                                        </td>
                                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                            <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                <button onClick={handleDeletedProblemAdd} className='btn bg-green-600 text-white  border-gree-500'>Add</button>
                                                <button onClick={(e) => handleDeletedProblem(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                            </div>
                                        </td>
                                    </>
                                ) : (
                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">

                                            {props.page === 'Editorials' && (
                                                <Link to={`/syllabus-content/editorials/${props.id ? encodeURIComponent(props.id) : encodeURIComponent(props.problem_id)}`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                            )}
                                            {props.page === 'PS' && (
                                                <Link to={`/syllabus-content/problem-setter/${props.id ? encodeURIComponent(props.id) : encodeURIComponent(props.problem_id)}`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                            )}
                                            {props.page === 'Video' && (
                                                <>
                                                    <button onClick={handleVideoEdit} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </>
                        )}
                    </>
                )}
            </tr>
            <CategoryModal title={props.page === 'Category' ? "Delete Category" : "Delete Problem"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                {props.page === 'Category' ? (
                    <div className='flex flex-col gap-y-4 items-center justify-center'>
                        <span className='text-4xl'>Are You Sure?</span>
                        <button className='btn-brand-2 mt-4' onClick={() => handleCatDelete()}>Yes, Delete</button>
                    </div>

                ) : (
                    <div className='flex flex-col gap-y-4 items-center justify-center'>
                        <span className='text-4xl'>Are You Sure?</span>
                        {props.page==="PB" ?
                        <button className='btn-brand-2 mt-4' onClick={() => handleProblemDelete()}>Yes, Delete</button>
                        :
                        <button className='btn-brand-2 mt-4' onClick={() => handleDeletedProblemDelete()}>Yes, Delete</button>
                }
                    </div>
                )}
            </CategoryModal>
        </tbody>
    )
}

export default CategoryTableItem