import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getDislikedProblems } from '../../services/operations/syllabus';
import DislikedProblemsTable from '../../components/Table/DislikedProblemsTable/DislikedProblems';

const DislikedProblems = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { dislikedProblems } = useSelector((state) => state.syllabus)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDislikedProblems())
    }, [dispatch]);

 
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">

                        <div className='flex mt-8 gap-y-2 flex-col justify-start'>

                            {dislikedProblems && dislikedProblems.length > 0 ? (
                                <DislikedProblemsTable dislikedProblems={dislikedProblems}/>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Disliked Problems to show
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DislikedProblems