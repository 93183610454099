import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReportedBugsTable from '../../components/Table/BugReportsTable/BugsTable'
import { getReportedBugs } from '../../services/operations/bugs'
import CategoryModal from '../../components/Shared/Modals/CategoryModal'

const ReportedBugs = ({ category, priority }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const bugsData = useSelector((state) => state.bugs[`${category}Bugs`]);
   
    useEffect(() => {
        dispatch(getReportedBugs(category, currentPage, priority));
    }, [dispatch, category, currentPage, priority]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };
   

    const renderCategoryBugs = (bugs) => {
        return (
            <div className="mb-8">
                {bugsData.data && bugsData.data.length > 0 ? (
                    <>
                        <ReportedBugsTable setModalOpen={setModalOpen} setContent={setContent} bugs={bugsData.data} category={category} currentPage={currentPage} />
                        <div className="flex justify-between mt-4">
                            <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
                        <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                    </div>
                )}
                <CategoryModal setModalOpen={setModalOpen} modalOpen={modalOpen}>
                    <div className="">
                        {content}
                    </div>
                </CategoryModal>
            </div>
        );
    };

    return (
        <main className="dark:bg-dark font-primary h-screen overflow-y-auto bg-[#fafafa]">
            {renderCategoryBugs(bugsData.data)}
        </main>
    );
};

export default ReportedBugs;