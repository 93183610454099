import React, { useState } from 'react'
import { setNotification } from '../../../slices/notificationSlice'
import { useDispatch } from 'react-redux'
import NotificationsModal from '../../Shared/Modals/NotificationModal'
import { deleteNotification } from '../../../services/operations/notifications'

const NotificationsTableItem = (props) => {
    const { setModalOpen, setIsEdit } = props

    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handleNotificationEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            title: props.title,
            message: props.message,
            emails: (props.emails || []).map(emailObj => emailObj.user).join(', '),
        };
        dispatch(setNotification(data))
        setIsEdit(true)
        setModalOpen(true)
    }
    const handleNotificationDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }
    
    const handleDeleteNotification = (e) => {
        dispatch(deleteNotification(props?.id, setModalDeleteOpen))
    }


    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.title}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.message}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.emails.map(emailObj => emailObj.user).join(', ')}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleNotificationEdit} className='btn bg-green-500 text-white  border-green-500 transition-all duration-300 ease-in-out '>Edit</button>
                        <button onClick={handleNotificationDeleteModal} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                    </div>
                </td>
            </tr>
            <NotificationsModal title={"Delete Notification"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleDeleteNotification()}>Yes, Delete</button>
                </div>
            </NotificationsModal>
        </tbody >
    )
}

export default NotificationsTableItem