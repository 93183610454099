import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import NotificationsModal from '../../components/Shared/Modals/NotificationModal';
import NotificationsTable from '../../components/Table/NotificationsTable/NotificationTable';
import { getAllNotifications, addNotification, updateNotification, sendEmailToPremiumUsers } from '../../services/operations/notifications';
import { setNotification } from '../../slices/notificationSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import toast from 'react-hot-toast'

const AddNotification = () => {
    
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { handleSubmit: handleEmailSubmit, control: emailControl, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted }, reset: resetEmail } = useForm({
        defaultValues: {
            sendEmailTitle: '', 
            sendEmailMessage: '', 
        }
    });
    
    const { allNotifications, notification } = useSelector((state) => state.notifications)

    useEffect(() => {
        dispatch(getAllNotifications())
    }, [dispatch])

    useEffect(() => {
        dispatch(setNotification({}))
    }, [dispatch]);

    useEffect(() => {
        reset(notification)
    }, [reset, notification]);

    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            title: '',
            message: '',
            emails: '',
        }
        
        dispatch(setNotification(data))
        setIsEdit(false)
        setModalOpen(true);
    }

    const handleOnSubmit = async (data) => {

        if (isEdit) {
            const { title, message, emails } = data;
            dispatch(updateNotification(encodeURIComponent(notification?.id), title, message, emails, setModalOpen));
        }
        else {
            const { title, message, emails } = data;
            dispatch(addNotification(title, message, emails, setModalOpen));
        }
    };

    const handleSendEmailModal = (e) => {
        e.stopPropagation()
        resetEmail()

        setEmailModalOpen(true);
    }
    
    const handleSendEmail = async (data) => {
        // console.log('Form data:', data);
        const { sendEmailTitle, sendEmailMessage } = data;
        setIsSending(true);
        const toastId = toast.loading('Sending emails. Please wait...');
        try {
            dispatch(sendEmailToPremiumUsers(sendEmailTitle, sendEmailMessage));
            toast.success('Emails sent successfully!', { id: toastId });
        } catch (error) {
            toast.error('An error occurred while sending emails.', { id: toastId });
        } 
        setIsSending(false);
        setEmailModalOpen(false);
    }

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add Notification</button>
                            <button onClick={(e) => handleSendEmailModal(e)} 
                                    disabled={isSending} 
                                    className={`btn-brand-2 ${isSending ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                    {isSending ? 'Sending...' : 'Send Email'}
                            </button>
                        </div>
                        {allNotifications && allNotifications.length > 0 ? (
                            <>
                                <NotificationsTable 
                                    notifications={allNotifications}
                                    setIsEdit={setIsEdit}
                                    setModalOpen={setModalOpen}
                                    modalOpen={modalOpen} 
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No notifications 
                            </div>
                        )}
                        <NotificationsModal title={isEdit ? 'Edit Notification' : 'Add Notification'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="title"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="title"
                                                    type="text"
                                                    name="title"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Title"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Title is required',
                                            }}
                                        />
                                        {isSubmitted && errors.title && <p className="text-red-600 text-sm italic">{errors.title.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="message"
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    id="message"
                                                    type="text"
                                                    name="message"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Message"
                                                    rows={3}
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Message is required',
                                            }}
                                        />
                                        {isSubmitted && errors.message && <p className="text-red-600 text-sm italic">{errors.message.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Emails</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="emails"
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    id="emails"
                                                    type="text"
                                                    name="emails"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Leave blank to notify all users . Enter comma separated emails for specific user notifications. Eg: raj@gmail.com, shivi@gmail.com, ...."
                                                    rows={3}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.emails && <p className="text-red-600 text-sm italic">{errors.emails.message}</p>}
                                    </div>
                                </div>
                               
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Add Notification</button>
                                    </div>
                                </div>
                            </form>
                        </NotificationsModal>
                        <NotificationsModal title={'Send Email To All Premium Users'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
                            <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="sendEmailTitle"
                                            control={emailControl}
                                            render={({ field }) => (
                                                <input
                                                    id="sendEmailTitle"
                                                    type="text"
                                                    name="sendEmailTitle"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Title"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Title is required',
                                            }}
                                        />
                                        {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="sendEmailMessage"
                                            control={emailControl}
                                            render={({ field }) => (
                                                <ReactQuill
                                                    id="sendEmailMessage"
                                                    theme="snow"
                                                    name="sendEmailMessage"
                                                    className="w-full text-gray-300"
                                                    placeholder="Email message to be sent."
                                                    rows={5}
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Message is required',
                                            }}
                                        />
                                        {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                                    </div>
                                </div>
                               
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                                    </div>
                                </div>
                            </form>
                        </NotificationsModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddNotification