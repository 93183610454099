/* eslint-disable no-unused-vars */
import { BookOpen, Clock4, Newspaper, ScrollText } from "lucide-react";
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import React, {useState, useEffect} from "react";
import ReportedBugs from "./BugReports";
import { useDispatch, useSelector } from 'react-redux'
import { getBugById } from '../../services/operations/bugs'
import { clearSelectedBug } from "../../slices/bugSlice";
import NotificationsModal from "../../components/Shared/Modals/NotificationModal";
import ReactQuill from "react-quill";
import { useForm, Controller } from "react-hook-form";
import { markStatus, sendEmailToUser } from "../../services/operations/bugs";
import toast from "react-hot-toast";

const Tabs = () => {
    const dispatch = useDispatch();
    const selectedBug = useSelector((state) => state.bugs.selectedBug);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openTab, setOpenTab] = useState(1);
    const [priority, setPriority] = useState('all');
    const [taskId, setTaskId] = useState('');
    const [bugStatus, setBugStatus] = useState(selectedBug?.status);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
    }
 
    const priorityOptions = ['resolved', 'unresolved', 'in progress', 'cannot be fixed', 'deferred'];

    const emailMessage = `
    <p>Hi,</p><br/>
    <p>Thank you for reaching out to us.</p>
    <p>Our team has resolved the bug titled <strong>${selectedBug?.title || ''}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
    <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
    <p>Thanks and Regards,<br/>takeUForward Team</p>
    `;

    const { handleSubmit: handleEmailSubmit, control: emailControl, setValue, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted } } = useForm({
        defaultValues: {
            sendEmailTitle: `TUF+ | Your BugId[${selectedBug?.report_id}]`, 
            sendEmailMessage: emailMessage, 
        }
    });

    useEffect(() => {
        if (selectedBug) {
            // Update form values with fetched bug details
            setValue('sendEmailTitle', `TUF+ | Your BugId[${selectedBug?.report_id}]`);
            setValue('sendEmailMessage', `
                <p>Hi,</p><br/>
                <p>Thank you for reaching out to us.</p>
                <p>Our team has resolved the bug titled <strong>${selectedBug?.title}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
                <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
                <p>Thanks and Regards,<br/>takeUForward Team</p>
            `);
        }
    }, [selectedBug, setValue]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (taskId.trim()) {
            dispatch(getBugById(taskId));
        } else {
            dispatch(clearSelectedBug());
        }
    }

    const handleStatusChange = async (newStatus) => {
        try {
            await dispatch(markStatus(selectedBug?.report_id, newStatus)); 
            setBugStatus(newStatus);
            setEmailModalOpen(true);
        } catch (error) {
            console.error("Error updating status:", error);
        }
    }

    const handleSendEmail = async (data) => {
        const { sendEmailTitle, sendEmailMessage } = data;
        setIsSending(true);
        const toastId = toast.loading('Sending email. Please wait...');
        try {
            dispatch(sendEmailToUser(sendEmailTitle, sendEmailMessage, selectedBug?.email));    
            toast.success('Email sent successfully!', { id: toastId });
        } catch (error) {
            toast.error('An error occurred while sending email.', { id: toastId });
        } 
        setIsSending(false);
        setEmailModalOpen(false);
    }

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <div className="mb-4 flex items-center gap-x-4 justify-between">
                        {/* Priority filter */}
                        <div className="flex items-center gap-x-4">
                            <label htmlFor="priority" className="text-md font-medium">Priority:</label>
                            <select
                                id="priority"
                                value={priority || 'all'}
                                onChange={handlePriorityChange}
                                className="form-select"
                            >
                                <option value="all">All</option>
                                <option value="high">High</option>
                                <option value="mid">Medium</option>
                                <option value="low">Low</option>
                            </select>
                        </div>
                        
                        {/* search bug by taskID */}
                        <form onSubmit={handleSearch} className="flex gap-x-2">
                            <input 
                                type="text"
                                value={taskId}
                                onChange={(e) => setTaskId(e.target.value)}
                                placeholder="Search by TaskID"
                                className="form-input"
                            />
                            <button type="submit" className="btn btn-primary">Search</button>
                        </form>
                    </div>

                    {/* Display fetched bug details if available */}
                    {selectedBug && (
                        <div className="mb-4 p-6 border rounded-lg dark:bg-dark_50 bg-white shadow-md">
                            <h3 className="text-2xl font-bold mb-4">{selectedBug.title}</h3>
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div>
                                <p className="mb-2"><strong>Category:</strong> {selectedBug.category}</p>
                                <p className="mb-2"><strong>Description:</strong> {selectedBug.description}</p>
                            </div>
                            <div>
                                <p className="mb-2"><strong>Priority:</strong> {selectedBug.priority}</p>
                                <p className="mb-2"><strong>Reported By:</strong> {selectedBug.reported_by}</p>
                                <p className="mb-2"><strong>Email:</strong> {selectedBug.email}</p>
                                <p className="mb-2"><strong>Time Reported:</strong> {new Date(selectedBug.timestamp).toISOString().split('T')[0]}</p>
                            </div>
                            </div>
                            
                            <div className="flex flex-col sm:flex-row items-center gap-4">
                            <div className="w-full sm:w-auto">
                                <label className="font-semibold mb-1 block">Update Status:</label>
                                <select
                                    value={selectedBug.status}
                                    onChange={(e) => handleStatusChange(e.target.value)} 
                                    className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                                >
                                    {priorityOptions.map(option => (
                                        <option key={option} value={option} className="bg-white dark:bg-zinc-800 text-gray-800 dark:text-zinc-200">
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            </div>
                        </div>
                    )}

                    <NotificationsModal title={'Send Email To User'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
                        <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                            <div className="flex flex-wrap -mx-3 mb-4">
                            <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                                <div className="w-full px-3">
                                    <Controller
                                        name="sendEmailTitle"
                                        control={emailControl}
                                        render={({ field }) => (
                                            <input
                                                id="sendEmailTitle"
                                                type="text"
                                                name="sendEmailTitle"
                                                className="form-input w-full text-gray-300"
                                                placeholder="Title"
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Title is required',
                                        }}
                                    />
                                    {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4">
                            <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                                <div className="w-full px-3">
                                    <Controller
                                        name="sendEmailMessage"
                                        control={emailControl}
                                        render={({ field }) => (
                                            <ReactQuill
                                                id="sendEmailMessage"
                                                theme="snow"
                                                name="sendEmailMessage"
                                                className="w-full text-gray-800 dark:text-zinc-200"
                                                placeholder="Email message to be sent."
                                                rows={5}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Message is required',
                                        }}
                                    />
                                    {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                                </div>
                            </div>
                                    
                            <div className="flex flex-wrap -mx-3 mt-6">
                                <div className="w-full px-3">
                                    <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                                </div>
                            </div>
                        </form>
                    </NotificationsModal> 

                    <div
                        className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                        role="tablist"
                    >
                        <div className="-mb-px flex-auto text-center">
                            <a
                                className={`
                                    text-sm font-bold uppercase px-4 py-3 flex gap-x-2 items-center leading-normal 
                                    ${openTab === 1
                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                    }
                                `}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#problem"
                                role="tablist"
                            >
                                <ScrollText
                                    size={18}
                                    className={`
                                        ${openTab === 1
                                            ? "stroke-brand transition-all ease-in-out duration-300"
                                            : "dark:stroke-light_10/40 stroke-light_30"
                                        }
                                    `}
                                />
                                <span>
                                    Problem
                                </span>
                            </a>
                        </div>
                        <div className="-mb-px  flex-auto text-center">
                            <a
                                className={`
                                text-sm font-bold uppercase px-3 py-3  leading-normal flex gap-x-2 items-center 
                                ${openTab === 2
                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                    }
                                `}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#editorial"
                                role="tablist"
                            >
                                <BookOpen
                                    size={18}
                                    className={`
                                        ${openTab === 2
                                            ? "stroke-brand transition-all ease-in-out duration-300"
                                            : "dark:stroke-light_10/40 stroke-light_30"
                                        }
                                    `}
                                />
                                <span>
                                    Editorial
                                </span>
                            </a>
                        </div>
                        <div className="-mb-px  flex-auto text-center">
                            <a
                                className={`
                                    text-sm font-bold uppercase px-3 py-3 flex gap-x-2 items-center leading-normal  
                                    ${openTab === 3
                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                    }
                                `}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(3);
                                }}
                                data-toggle="tab"
                                href="#video"
                                role="tablist"
                            >
                                <Clock4
                                    size={18}
                                    className={`
                                        ${openTab === 3
                                            ? "stroke-brand transition-all ease-in-out duration-300"
                                            : "dark:stroke-light_10/40 stroke-light_30"
                                        }
                                    `}
                                />
                                <span>
                                    Video
                                </span>
                            </a>
                        </div>
                        <div className="-mb-px flex-auto text-center">
                            <a
                                className={`
                                    text-sm font-bold uppercase px-3 py-3 flex gap-x-2 items-center leading-normal
                                    ${openTab === 4
                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                    }
                                `}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(4);
                                }}
                                data-toggle="tab"
                                href="#tech"
                                role="tablist"
                            >
                                <svg className={`lucide lucide-message-circle-question
                                        ${openTab === 4
                                        ? "stroke-brand transition-all ease-in-out duration-300"
                                        : "dark:stroke-light_10/40 stroke-light_30"
                                    }
                                    `} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <path d="M12 17h.01" />
                                </svg>
                                <span>
                                    Tech
                                </span>
                            </a>
                        </div>
                        <div className="-mb-px  flex-auto text-center">
                            <a
                                className={`
                                    text-sm font-bold uppercase px-3 py-3 flex gap-x-2 items-center leading-normal  
                                    ${openTab === 5
                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                    }
                                `}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(5);
                                }}
                                data-toggle="tab"
                                href="#others"
                                role="tablist"
                            >
                                <svg className={`lucide lucide-notebook-pen 
                                        ${openTab === 5
                                        ? "stroke-brand transition-all ease-in-out duration-300"
                                        : "dark:stroke-light_10/40 stroke-light_30"
                                    }
                                    `} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4" /><path d="M2 6h4" />
                                    <path d="M2 10h4" />
                                    <path d="M2 14h4" />
                                    <path d="M2 18h4" />
                                    <path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z" />
                                </svg>
                                <span>
                                    others
                                </span>
                            </a>
                        </div>


                    </div>
                    <div className="relative flex flex-col min-w-0 break-words  w-full mb-2">
                        <div className="dark:bg-dark_50 bg-white p-4 border rounded-lg border-light_10 dark:border-dark_40 flex-auto">
                            <div className="tab-content tab-space max-h-[calc(100vh-250px)] overflow-y-auto">
                                <div className={openTab === 1 ? "block" : "hidden"} id="problem">
                                    <ReportedBugs category='problem' priority={priority}/>
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="editorial">
                                    <ReportedBugs category='editorial' priority={priority}/>
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="video">
                                    <ReportedBugs category='video' priority={priority}/>
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="tech">
                                    <ReportedBugs category='tech' priority={priority}/>
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="others">
                                    <ReportedBugs category='others' priority={priority}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function TabsRender() {
    return (
        <>
            <Tabs />
        </>
    );
}