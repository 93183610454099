import { createSlice } from '@reduxjs/toolkit';

const judgeStatsSlice = createSlice({
  name: 'judgeStats',
  initialState: {
    runSubmitStats: [],
  },
  reducers: {
    setRunSubmitStats: (state, action) => {
      state.runSubmitStats = action.payload;
    },
  },
});

export const { setRunSubmitStats } = judgeStatsSlice.actions;
export default judgeStatsSlice.reducer;