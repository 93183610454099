import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {},
    allUsers: [],
};

const grantAccessSlice = createSlice({
    name: "grantAccess",
    initialState: initialState,
    reducers: {
        setUser(state, value) {
            state.user = value.payload;
        },
        setAllUsers(state, value) {
            state.allUsers = value.payload;
        },
    },
});

export const { setUser, setAllUsers } = grantAccessSlice.actions;
export default grantAccessSlice.reducer;