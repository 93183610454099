import React from 'react'
import SessionsTableItem from './SessionsTableItem'

const SessionsTable = ({ sessions, setModalOpen, setContent, setCopyModalOpen, setIsEdit }) => {
    return (
        <div className="  font-primary  relative w-full">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Topic</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Date</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Time</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  w-1/3 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Link</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Max People</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Enrolled Users</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Enrolled Emails</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            sessions?.map((data) => {
                                return (
                                    <SessionsTableItem
                                        key={data?.id}
                                        id={data?.id}
                                        topic={data?.meeting_name}
                                        date={data?.meeting_date}
                                        time={data?.meeting_time}
                                        link={data?.meeting_link}
                                        maxPeople={data?.max_people}
                                        enrolledPeople={data?.enrolled_people}
                                        video={data?.video}
                                        thumbnail={data?.thumbnail}
                                        setModalOpen={setModalOpen}
                                        setIsEdit={setIsEdit}
                                        setContent={setContent}
                                        setCopyModalOpen={setCopyModalOpen}
                                    />
                                )
                            })
                        }
                        
                    </table>

                </div>
            </div>
        </div>
    )
}

export default SessionsTable


