import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    category: {},
    subCategory: {},
    problem: {},
    problemSetter: {},
    allProblems: [],
    allDeletedProblems: [],
    allCategories: [],
    allSubCategories: [],
    imageFileLinks: {},
    loading: false,
    dislikedProblems: [],
};

const authSlice = createSlice({
    name: "syllabus",
    initialState: initialState,
    reducers: {
        setImageFileLinks(state, value) {
            state.imageFileLinks = value.payload;
        },
        setCategory(state, value) {
            state.category = value.payload;
        },
        setSubCategory(state, value) {
            state.subCategory = value.payload;
        },
        setProblem(state, value) {
            state.problem = value.payload;
        },
        setProblemSetter(state, value) {
            state.problemSetter = value.payload;
        },
        setAllProblems(state, value) {
            state.allProblems = value.payload;
        },
        setAllDeletedProblems(state, value) {
            state.allDeletedProblems = value.payload;
        },
        setAllCategories(state, value) {
            state.allCategories = value.payload;
        },
        setAllSubCategories(state, value) {
            state.allSubCategories = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setDislikedProblems(state, value) {
            state.dislikedProblems = value.payload;
        }
    },
});

export const { setLoading, setCategory, setSubCategory, setProblems, setProblem, setAllCategories, setAllSubCategories, setAllProblems,setAllDeletedProblems, setProblemSetter, setImageFileLinks, setDislikedProblems } = authSlice.actions;

export default authSlice.reducer;
