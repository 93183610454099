import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { apiConnector } from '../../services/apiConnector';
import { Errors } from '../../services/api';

const ErrorDashboard = ()=> {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState("all");
  const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString('en-GB').split('/').join('-'));
  const [timeRange, setTimeRange] = useState("all");
  const [customStartTime, setCustomStartTime] = useState("14:00");
  const [customEndTime, setCustomEndTime] = useState("16:00");
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedError, setSelectedError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);



  const fetchErrors = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        search: searchQuery,
        tag: selectedTag,
        date: selectedDate,
        customStartTime: timeRange === 'custom' ? customStartTime : getTime(timeRange),
        customEndTime: timeRange === 'custom' ? customEndTime : new Date().toString().substring(16, 21),
      }).toString();

      const res = await apiConnector("GET", Errors.GET_ERRORS + queryParams )
      setErrors(res.data.logs);
    } catch (error) {
      console.error("Error fetching error data:", error);
      setError('Failed to fetch errors. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchErrors();
    // eslint-disable-next-line
  }, [searchQuery, selectedTag, selectedDate, timeRange, customStartTime, customEndTime]);

  const getTime=(timeRange)=>{
    const date = new Date();
    switch (timeRange) {
      case 'last5m': {
        date.setMinutes(date.getMinutes() - 5);
        break;
      }
      case 'last15m': {
        date.setMinutes(date.getMinutes() - 15);
        break;
      }
      case 'last30m': {
        date.setMinutes(date.getMinutes() - 30);
        break;
      }
      case 'last1h': {
        date.setHours(date.getHours() - 1);
        break;
      }
      case 'last3h': {
        date.setHours(date.getHours() - 3);
        break;
      }
      case 'last6h': {
        date.setHours(date.getHours() - 6);
        break;
      }
      case 'last12h': {
        date.setHours(date.getHours() - 12);
        break;
      }
      case 'all':{
        return "";
      }
      default: {
        break;
      }
    }
    if (new Date().getDate() !== date.getDate()) {

      date.setHours(0);
      date.setMinutes(0);
      
    }
    return date.toString().substring(16, 21);
  }



  return (
    <div className="flex h-screen overflow-hidden bg-zinc-100 dark:bg-zinc-900">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="min-h-screen p-8 bg-zinc-100 dark:bg-zinc-900 text-zinc-800 dark:text-white">
          <div className="max-w-6xl mx-auto">
            <h1 className="text-4xl font-bold mb-8">Error Dashboard</h1>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-md p-6 mb-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} setTimeRange={setTimeRange} />
                <TimeRangeSelector
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  selectedDate={selectedDate}
                  customStartTime={customStartTime}
                  setCustomStartTime={setCustomStartTime}
                  customEndTime={customEndTime}
                  setCustomEndTime={setCustomEndTime}
                />
              </div>
              <TagSelector selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
            </div>
            <ErrorList
              isLoading={isLoading}
              error={error}
              errors={errors}
              setSelectedError={setSelectedError}
              setModalOpen={setModalOpen}
            />
          </div>
        </div>
      </div>
      <ErrorModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedError={selectedError}
      />
    </div>
  );
}

const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <div className="col-span-1 md:col-span-2">
    <input
      type="text"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder="Search errors..."
      className="w-full px-4 py-2 rounded-md border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
);

const DateSelector = ({ selectedDate, setSelectedDate, setTimeRange }) => {
  const getLastSevenDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - i);
      days.push(currentDate.toLocaleDateString('en-GB').split('/').join('-'));
    }
    return days;
  };

  return (
    <div>
      <select
        value={selectedDate}
        onChange={(e) => {setSelectedDate(e.target.value); setTimeRange("all");}}
        className="w-full px-4 py-2 rounded-md border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {getLastSevenDays().map(day => (
          <option key={day} value={day}>{day}</option>
        ))}
      </select>
    </div>
  );
};

const TimeRangeSelector = ({ timeRange, setTimeRange, selectedDate, customStartTime, setCustomStartTime, customEndTime, setCustomEndTime }) => {
  const timeRanges = [
    { value: "all", label: "All Day" },
    { value: "last5m", label: "Last 5 Minutes" },
    { value: "last15m", label: "Last 15 Minutes" },
    { value: "last30m", label: "Last 30 Minutes" },
    { value: "last1h", label: "Last 1 Hour" },
    { value: "last3h", label: "Last 3 Hours" },
    { value: "last6h", label: "Last 6 Hours" },
    { value: "last12h", label: "Last 12 Hours" },
    { value: "custom", label: "Custom Range" },
  ];

  return (
    <div>
      <select
        value={timeRange}
        onChange={(e) => setTimeRange(e.target.value)}
        className="w-full px-4 py-2 rounded-md border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {new Date().toLocaleDateString('en-GB').split('/').join('-') === selectedDate ?
          timeRanges.map(range => (
            <option key={range.value} value={range.value}>{range.label}</option>
          )) : (
            <>
              <option value="all">All Day</option>
              <option value="custom">Custom Range</option>
            </>
          )}  
      </select>
      {timeRange === 'custom' && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label htmlFor="customStartTime" className="block text-sm font-medium mb-1 text-zinc-700 dark:text-zinc-300">
              Start Time
            </label>
            <input
              type="time"
              id="customStartTime"
              value={customStartTime}
              onChange={(e) => setCustomStartTime(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="customEndTime" className="block text-sm font-medium mb-1 text-zinc-700 dark:text-zinc-300">
              End Time
            </label>
            <input
              type="time"
              id="customEndTime"
              value={customEndTime}
              onChange={(e) => setCustomEndTime(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-zinc-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const TagSelector = ({ selectedTag, setSelectedTag }) => {
  const tags = [
    "all", "auth", "bugs", "cashback", "contest", "course", "discussion", "doubt",
    "leaderboard", "notification", "onlineJudge", "partner", "payment",
    "profile", "referral", "roadmap", "sessionDoubt", "session",
  ];

  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag) => (
        <button
          key={tag}
          onClick={() => setSelectedTag(tag)}
          className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
            selectedTag === tag
              ? "bg-blue-500 text-white"
              : "bg-zinc-200 text-zinc-800 hover:bg-zinc-300 dark:bg-zinc-700 dark:text-zinc-300 dark:hover:bg-zinc-600"
          }`}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

const ErrorList = ({ isLoading, error, errors, setSelectedError, setModalOpen }) => {
  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-zinc-900 dark:border-white"></div>
        <p className="mt-2 text-zinc-600 dark:text-zinc-400">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 dark:bg-red-900 border-l-4 border-red-500 text-red-700 dark:text-red-200 p-4 rounded">
        <p className="font-bold">Error</p>
        <p>{error}</p>
      </div>
    );
  }

  if (errors.length === 0) {
    return (
      <div className="bg-zinc-100 dark:bg-zinc-800 border-l-4 border-zinc-500 dark:border-zinc-600 text-zinc-700 dark:text-zinc-300 p-4 rounded">
        <p className="font-bold">No errors found</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {errors.map((err) => (
        <div
          key={err.id}
          onClick={() => {
            setSelectedError(err);
            setModalOpen(true);
          }}
          className="bg-white dark:bg-zinc-800 rounded-lg shadow-md p-6 cursor-pointer"
        >
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-2">{new Date(err.timestamp).toLocaleString()}</p>
          <p className="font-medium">{err.message}</p>
        </div>
      ))}
    </div>
  );
};

const ErrorModal = ({ modalOpen, setModalOpen, selectedError }) => {
  if (!modalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-60">
      <div className="bg-white dark:bg-zinc-800 rounded-lg mx-20 w-full ">
        <div className="p-6 border-b border-zinc-200 dark:border-zinc-700">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">{selectedError?.message}</h2>
            <button
              onClick={() => setModalOpen(false)}
              className="text-zinc-600 dark:text-zinc-400 hover:text-zinc-800 dark:hover:text-white"
            >
              ✕
            </button>
          </div>
        </div>
        <div className="p-6 overflow-y-auto max-h-[80vh]">
          <pre className="bg-zinc-100 dark:bg-zinc-900 p-4 rounded-lg overflow-x-auto">
            <code className="text-zinc-800 dark:text-zinc-300">{selectedError?.stack}</code>
          </pre>
        </div>
      </div>
    </div>
  );
};


export default ErrorDashboard