import { File, Folder, FolderOpen, Upload, UserRoundPlus, BugIcon, BombIcon } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import { useSelector } from 'react-redux';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const { signinData } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = location;
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;

      // Check if the click target is the button
      const isButtonClick = trigger.current.contains(target);

      if (!sidebarOpen || sidebar.current.contains(target) || isButtonClick) {
        // Prevent event propagation if the click is on the button
        if (isButtonClick) {
          return;
        }
        setSidebarOpen(false);
      }
    };

    document.addEventListener('click', clickHandler);

    return () => document.removeEventListener('click', clickHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleSideBar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // console.log(sidebarExpanded)
  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        aria-hidden='true'
      ></div>
      <div
        id='sidebar'
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar lg:w-20 w-64  lg:sidebar-expanded:!w-64 2xl:!w-64 flex-shrink-0 bg-dark motion-reduce:transition-none dark:border-zinc-800 border-zinc-200 border-r p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >
        <div className='flex justify-between mb-10 pr-3 sm:px-2'>
          <button
            ref={trigger}
            className='lg:hidden text-gray-500 hover:text-gray-400'
            onClick={() => handleSideBar()}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <span className='sr-only'>Close sidebar</span>
            <svg
              className='w-6 h-6 fill-current'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
            </svg>
          </button>
          <NavLink exact to='/admin' className='flex items-center gap-2'>
            <svg
              width='30'
              height='30'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='20' cy='20' r='20' fill='#D4162D' />
              <path
                d='M3.27871 17.519H6.59052L5.26579 24.7618H8.24642L9.57114 17.519H13.2141L13.5453 15.8729H3.60989L3.27871 17.519Z'
                fill='white'
              />
              <path
                d='M17.5195 15.8729H14.5389L13.2141 22.4572L15.2012 24.7618H23.4807L24.8055 15.8729H21.8248L20.5001 23.1157H16.8571L16.5259 22.4572L17.5195 15.8729Z'
                fill='white'
              />
              <path
                d='M24.4743 24.7618L25.4678 18.1774L27.7861 15.8729H36.0656L35.4032 18.8358H32.7538V17.519H29.1108L28.1173 18.1774L27.7861 20.1527H35.0721V21.7988H27.4549L27.1237 24.7618H24.4743Z'
                fill='white'
              />
            </svg>
            <span
              className={`font-amaranth text-stone-100 font-bold text-3xl ${sidebarExpanded ? 'block' : 'hidden 2xl:block'}`}
            >
              takeUforward
            </span>
          </NavLink>
        </div>

        <div className='space-y-4'>
          {/* {signinData?.role === 'Admin' && ( */}
          <SidebarLinkGroup activecondition={pathname.includes('manage-syllabus')}>
            {(handleClick, open) => {
              return (
                <React.Fragment>
                  <a
                    href='#0'
                    className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('manage-syllabus') && 'hover:text-gray-200  '}`}
                    onClick={(e) => {
                      e.preventDefault();
                      sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                    }}
                  >
                    <div className='flex items-center justify-between '>
                      <div className='flex items-center '>
                        {open ? (
                          <FolderOpen
                            className={`text-gray-400 ${pathname.includes('manage-syllabus') && '!text-orange-600'}`}
                          />
                        ) : (
                          <Folder
                            className={`text-gray-400 ${pathname.includes('manage-syllabus') && '!text-orange-600'}`}
                          />
                        )}
                        <span
                          className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('manage-syllabus') ? 'text-brand font-bold ' : 'font-medium'}`}
                        >
                          Manage Syllabus
                        </span>
                      </div>
                      <div className='flex flex-shrink-0 ml-2'>
                        <svg
                          className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                          viewBox='0 0 12 12'
                        >
                          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
                    <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                      <div className='py-2 flex relative'>
                        <div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-syllabus/category`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-syllabus/category') && pathname.includes('manage-syllabus/category') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-syllabus/category`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-syllabus/category`) && 'text-brand_50'}`}
                                >
                                  Category
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-syllabus/sub-category`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-syllabus/sub-category') && pathname.includes('manage-syllabus/sub-category') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-syllabus/sub-category`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-syllabus/sub-category`) && 'text-brand_50'}`}
                                >
                                  Sub Category
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-syllabus/problems`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-syllabus/problems') && pathname.includes('manage-syllabus/problems') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-syllabus/problems`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-syllabus/problems`) && 'text-brand_50'}`}
                                >
                                  Problems
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-syllabus/deletedproblems`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-syllabus/deletedproblems') && pathname.includes('manage-syllabus/deletedproblems') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-syllabus/deletedproblems`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-syllabus/deletedproblems`) && 'text-brand_50'}`}
                                >
                                  Deleted Problems
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-syllabus/dislikedproblems`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-syllabus/dislikedproblems') && pathname.includes('manage-syllabus/dislikedproblems') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-syllabus/dislikedproblems`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-syllabus/dislikedproblems`) && 'text-brand_50'}`}
                                >
                                  Disliked Problems
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
          </SidebarLinkGroup>
          <SidebarLinkGroup activecondition={pathname.includes('syllabus-content')}>
            {(handleClick, open) => {
              return (
                <React.Fragment>
                  <a
                    href='#0'
                    className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('syllabus-content') && 'hover:text-gray-200  '}`}
                    onClick={(e) => {
                      e.preventDefault();
                      sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                    }}
                  >
                    <div className='flex items-center justify-between '>
                      <div className='flex items-center '>
                        {open ? (
                          <FolderOpen
                            className={`text-gray-400 ${pathname.includes('syllabus-content') && '!text-orange-600'}`}
                          />
                        ) : (
                          <Folder
                            className={`text-gray-400 ${pathname.includes('syllabus-content') && '!text-orange-600'}`}
                          />
                        )}
                        <span
                          className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('syllabus-content') ? 'text-brand font-bold ' : 'font-medium'}`}
                        >
                          Syllabus Content
                        </span>
                      </div>
                      <div className='flex flex-shrink-0 ml-2'>
                        <svg
                          className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                          viewBox='0 0 12 12'
                        >
                          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
                    <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                      <div className='py-2 flex relative'>
                        <div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/syllabus-content/video`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('syllabus-content/video') && pathname.includes('syllabus-content/video') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`syllabus-content/video`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`syllabus-content/video`) && 'text-brand_50'}`}
                                >
                                  Video
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/syllabus-content/editorials`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('syllabus-content/editorials') && pathname.includes('syllabus-content/editorials') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`syllabus-content/editorials`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`syllabus-content/editorials`) && 'text-brand_50'}`}
                                >
                                  Editorials
                                </span>
                              </div>
                            </NavLink>
                          </div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/syllabus-content/problem-setter`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('syllabus-content/problem-setter') && pathname.includes('syllabus-content/problem-setter') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`syllabus-content/problem-setter`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`syllabus-content/problem-setter`) && 'text-brand_50'}`}
                                >
                                  Problem Setter
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
          </SidebarLinkGroup>
          <SidebarLinkGroup activecondition={pathname.includes('manage-sessions')}>
            {(handleClick, open) => {
              return (
                <React.Fragment>
                  <a
                    href='#0'
                    className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('manage-sessions') && 'hover:text-gray-200  '}`}
                    onClick={(e) => {
                      e.preventDefault();
                      sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                    }}
                  >
                    <div className='flex items-center justify-between '>
                      <div className='flex items-center '>
                        {open ? (
                          <FolderOpen
                            className={`text-gray-400 ${pathname.includes('manage-sessions') && '!text-orange-600'}`}
                          />
                        ) : (
                          <Folder
                            className={`text-gray-400 ${pathname.includes('manage-sessions') && '!text-orange-600'}`}
                          />
                        )}
                        <span
                          className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('manage-sessions') ? 'text-brand font-bold ' : 'font-medium'}`}
                        >
                          Manage Sessions
                        </span>
                      </div>
                      <div className='flex flex-shrink-0 ml-2'>
                        <svg
                          className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                          viewBox='0 0 12 12'
                        >
                          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
                    <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                      <div className='py-2 flex relative'>
                        <div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-sessions`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-sessions') && pathname.includes('manage-sessions') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-sessions`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-sessions`) && 'text-brand_50'}`}
                                >
                                  Sessions
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
          </SidebarLinkGroup>
          <SidebarLinkGroup activecondition={pathname.includes('manage-notifications')}>
            {(handleClick, open) => {
              return (
                <React.Fragment>
                  <a
                    href='#0'
                    className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('manage-notifications') && 'hover:text-gray-200  '}`}
                    onClick={(e) => {
                      e.preventDefault();
                      sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                    }}
                  >
                    <div className='flex items-center justify-between '>
                      <div className='flex items-center '>
                        {open ? (
                          <FolderOpen
                            className={`text-gray-400 ${pathname.includes('manage-notifications') && '!text-orange-600'}`}
                          />
                        ) : (
                          <Folder
                            className={`text-gray-400 ${pathname.includes('manage-notifications') && '!text-orange-600'}`}
                          />
                        )}
                        <span
                          className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('manage-notifications') ? 'text-brand font-bold ' : 'font-medium'}`}
                        >
                          Manage Notifications
                        </span>
                      </div>
                      <div className='flex flex-shrink-0 ml-2'>
                        <svg
                          className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                          viewBox='0 0 12 12'
                        >
                          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
                    <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                      <div className='py-2 flex relative'>
                        <div>
                          <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                            <NavLink
                              exact
                              to={`/manage-notifications`}
                              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-notifications') && pathname.includes('manage-notifications') && 'hover:text-gray-200 font-bold'}`}
                            >
                              <div className='flex items-center'>
                                <File
                                  size={20}
                                  className={`${pathname.includes(`manage-notifications`) && 'stroke-brand_50'}`}
                                />
                                <span
                                  className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-notifications`) && 'text-brand_50'}`}
                                >
                                  Notifications
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
          </SidebarLinkGroup>

          {signinData?.role === 'Admin' && (
            <SidebarLinkGroup activecondition={pathname.includes('manage-premium')}>
              {(handleClick, open) => {
                return (
                  <React.Fragment>
                    <a
                      href='#0'
                      className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes('manage-premium') && 'hover:text-gray-200  '}`}
                      onClick={(e) => {
                        e.preventDefault();
                        sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                      }}
                    >
                      <div className='flex items-center justify-between '>
                        <div className='flex items-center '>
                          {open ? (
                            <FolderOpen
                              className={`text-gray-400 ${pathname.includes('manage-premium') && '!text-orange-600'}`}
                            />
                          ) : (
                            <Folder
                              className={`text-gray-400 ${pathname.includes('manage-premium') && '!text-orange-600'}`}
                            />
                          )}
                          <span
                            className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('manage-premium') ? 'text-brand font-bold ' : 'font-medium'}`}
                          >
                            Manage Premium
                          </span>
                        </div>
                        <div className='flex flex-shrink-0 ml-2'>
                          <svg
                            className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                            viewBox='0 0 12 12'
                          >
                            <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                          </svg>
                        </div>
                      </div>
                    </a>
                    <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
                      <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                        <div className='py-2 flex relative'>
                          <div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/plans`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-premium/plans') && pathname.includes('manage-premium/plans') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`manage-premium/plans`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-premium/plans`) && 'text-brand_50'}`}
                                  >
                                    Plans
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/coupons`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-premium/coupons') && pathname.includes('manage-premium/coupons') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`manage-premium/coupons`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-premium/coupons`) && 'text-brand_50'}`}
                                  >
                                    Coupon Codes
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/affiliates`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-premium/affiliates') && pathname.includes('manage-premium/affiliates') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`manage-premium/affiliates`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-premium/affiliates`) && 'text-brand_50'}`}
                                  >
                                    Affiliate Details
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/privileged-users`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('manage-premium/privileged-users') && pathname.includes('manage-premium/privileged-users') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`manage-premium/privileged-users`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`manage-premium/privileged-users`) && 'text-brand_50'}`}
                                  >
                                    Privileged Users
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/add-config`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('/manage-premium/add-config') && pathname.includes('/manage-premium/add-config') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`/manage-premium/add-config`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`/manage-premium/add-config`) && 'text-brand_50'}`}
                                  >
                                    Configs Table
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            {/* <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                                                            <NavLink exact to={`/manage-premium/total-sales`} className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('/manage-premium/total-sales') && pathname.includes('/manage-premium/total-sales') && 'hover:text-gray-200 font-bold'}`}>
                                                                <div className="flex items-center">
                                                                    <File size={20} className={`${pathname.includes(`/manage-premium/total-sales`) && 'stroke-brand_50'}`} />
                                                                    <span className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`/manage-premium/total-sales`) && 'text-brand_50'}`}>Sales Tracker</span>
                                                                </div>
                                                            </NavLink>
                                                        </div> */}
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/doubt-costs`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('/manage-premium/doubt-costs') && pathname.includes('/manage-premium/doubt-costs') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`/manage-premium/doubt-costs`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`/manage-premium/doubt-costs`) && 'text-brand_50'}`}
                                  >
                                    Doubt Costs
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/judge-stats`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('/manage-premium/judge-stats') && pathname.includes('/manage-premium/judge-stats') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`/manage-premium/judge-stats`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`/manage-premium/judge-stats`) && 'text-brand_50'}`}
                                  >
                                    Judge Stats
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                            <div className={`px-3 py-2  rounded-lg last:mb-0`}>
                              <NavLink
                                exact
                                to={`/manage-premium/discussions`}
                                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('/manage-premium/discussions') && pathname.includes('/manage-premium/discussions') && 'hover:text-gray-200 font-bold'}`}
                              >
                                <div className='flex items-center'>
                                  <File
                                    size={20}
                                    className={`${pathname.includes(`/manage-premium/discussions`) && 'stroke-brand_50'}`}
                                  />
                                  <span
                                    className={`text-sm font-medium ml-3  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(`/manage-premium/discussions`) && 'text-brand_50'}`}
                                  >
                                    Discussions
                                  </span>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            </SidebarLinkGroup>
          )}
          {/* // )} */}
          {signinData?.role === 'Admin' && (
            <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
              <NavLink
                exact
                to='/add-user'
                className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('add-user') && 'hover:text-gray-200'}`}
              >
                <div className='flex items-center'>
                  <UserRoundPlus
                    className={`flex-shrink-0 h-5 w-5 ${pathname.includes('add-user') && 'stroke-brand_50'}`}
                  />
                  <span
                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('add-user') && 'text-brand_50'}`}
                  >
                    Add User
                  </span>
                </div>
              </NavLink>
            </div>
          )}
          {/* {signinData?.role === 'Admin' && ( */}
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink
              exact
              to='/create-editor'
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('create-editor') && 'hover:text-gray-200'}`}
            >
              <div className='flex items-center'>
                <UserRoundPlus
                  className={`flex-shrink-0 h-5 w-5 ${pathname.includes('create-editor') && 'stroke-brand_50'}`}
                />
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('create-editor') && 'text-brand_50'}`}
                >
                  Code Editor
                </span>
              </div>
            </NavLink>
          </div>
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink
              exact
              to='/create-image-slider'
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('create-image-slider') && 'hover:text-gray-200'}`}
            >
              <div className='flex items-center'>
                <UserRoundPlus
                  className={`flex-shrink-0 h-5 w-5 ${pathname.includes('create-image-slider') && 'stroke-brand_50'}`}
                />
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('create-image-slider') && 'text-brand_50'}`}
                >
                  Image Slider
                </span>
              </div>
            </NavLink>
          </div>
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink
              exact
              to='/upload-text-file'
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('upload-text-file') && 'hover:text-gray-200'}`}
            >
              <div className='flex items-center'>
                <Upload
                  className={`flex-shrink-0 h-5 w-5 ${pathname.includes('upload-text-file') && 'stroke-brand_50'}`}
                />
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('upload-text-file') && 'text-brand_50'}`}
                >
                  Upload Text File
                </span>
              </div>
            </NavLink>
          </div>
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink
              exact
              to='/upload-image'
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('upload-image') && 'hover:text-gray-200'}`}
            >
              <div className='flex items-center'>
                <Upload
                  className={`flex-shrink-0 h-5 w-5 ${pathname.includes('upload-image') && 'stroke-brand_50'}`}
                />
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('upload-image') && 'text-brand_50'}`}
                >
                  Upload Image
                </span>
              </div>
            </NavLink>
          </div>
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink
              exact
              to='/reported-bugs'
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('reported-bugs') && 'hover:text-gray-200'}`}
            >
              <div className='flex items-center'>
                <BugIcon
                  className={`flex-shrink-0 h-5 w-5 ${pathname.includes('reported-bugs') && 'stroke-brand_50'}`}
                />
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('reported-bugs') && 'text-brand_50'}`}
                >
                  Reported Bugs
                </span>
              </div>
            </NavLink>
          </div>
          <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
            <NavLink exact to="/errors" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('reported-bugs') && 'hover:text-gray-200'}`}>
                <div className="flex items-center">
                    <BombIcon className={`flex-shrink-0 h-5 w-5 ${pathname.includes('errors') && 'stroke-brand_50'}`}
                    />
                    <span
                        className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('errors') && 'text-brand_50'}`}>
                        Errors
                    </span>
                </div>
            </NavLink>
          </div>
          
          {/* )} 

                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/upload-image" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('upload-image') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <Upload className={`flex-shrink-0 h-5 w-5 ${pathname.includes('upload-image') && 'stroke-brand_50'}`} />
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('upload-image') && 'text-brand_50'}`}>
                                    Upload Image
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    {/* {signinData?.role === 'Admin' && ( */}
          {/* <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <button className='px-3 py-2 rounded-lg text-white bg-brand hover:bg-brand_50 w-fit text-sm'>
                            Clear Cache
                        </button>
                    </div> */}
          {/* )} */}
        </div>

        {/* Expand / collapse button */}
        <div className='pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto'>
          <div className='px-3 py-2'>
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className='sr-only'>Expand / collapse sidebar</span>
              <svg className='w-6 h-6 fill-current sidebar-expanded:rotate-180' viewBox='0 0 24 24'>
                <path
                  className='text-gray-400'
                  d='M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z'
                />
                <path className='text-gray-600' d='M3 23H1V1h2z' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
