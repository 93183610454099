import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from '../../../components/Table/CategoryTable/CategoryTable'
import ContentAccordion from '../../../components/Accordion/ContentAccordion';
import { clearCacheJudge } from '../../../services/operations/syllabus';
const ProblemSetter = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { allProblems } = useSelector((state) => state.syllabus)
    const dispatch = useDispatch()

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-[#f5f5f5]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <span className='text-2xl'>Problem Setter</span>
                            {/*  <div className=" relative w-fit">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-white placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500"
                                    type="search" placeholder="Search…"
                                // value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="absolute inset-0 right-auto group" aria-label="Search">
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div> 
                            */}
                            <button onClick={(e) => { e.stopPropagation(); dispatch(clearCacheJudge()) }} className='btn text-[16px] font-medium' >Clear Cache Post Save</button>
                        </div>
                        {allProblems && allProblems.length > 0 ? (
                            allProblems.map((categories) => (
                                <div key={categories?.id}>
                                    <ContentAccordion
                                        title={categories?.name}
                                    >

                                        {categories?.subcategories?.map((subCategories) => (
                                            <div key={subCategories.id} className='p-2'>

                                                <ContentAccordion
                                                    title={subCategories.name}>

                                                    <div className='p-3'>
                                                        <CategoryTable page="PS" topics={subCategories.problems} />
                                                    </div>

                                                </ContentAccordion>
                                            </div>
                                        ))}

                                    </ContentAccordion>
                                </div>
                            )))
                            : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Problems to show
                                </div>
                            )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default ProblemSetter