import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { delDiscussion } from '../../../services/operations/comments';
import UsersModal from '../../Shared/Modals/UsersModal';

const DiscussionsTableItem = (props) => {
    const { setContent, setModalOpen, num, content, d_id, date, currentPage } = props
    const dispatch = useDispatch();
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
   
    const formatDate = (date) => {
        const d = new Date(date);
        if (isNaN(d.getTime())) {
            throw new Error("Invalid date format");
        }
        return d.toISOString().split('T')[0];
    };
        
    const handleDeleteDiscussion = () => {
        console.log("inside del discussion")
        dispatch(delDiscussion(d_id, setModalDeleteOpen, currentPage));
    }
    
    const handleView = (e) => {
        e.stopPropagation();
        setModalOpen(true)
        setContent(content)
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        console.log("inside del click")
        setModalDeleteOpen(true);
        console.log("Modal state:", modalDeleteOpen); 
    };

    return (
    <>
        <tbody className="text-[13px] overflow-y-auto">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(date)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {content.split('\n')[0].slice(0, 100)}...
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleView(e)} className="text-brand underline" type='button'>View</button>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button 
                            onClick={(e) => handleDeleteClick(e)}
                            className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>
                            Delete
                        </button>
                    </div>
                </td>
            </tr>
            <UsersModal title={"Delete Discussion"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={handleDeleteDiscussion}>Yes, Delete</button>
                </div>
            </UsersModal>
        </tbody>
    </>
    );
};

export default DiscussionsTableItem;
