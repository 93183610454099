import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory } from '../../slices/syllabusSlice';
import { Controller, useForm } from 'react-hook-form';
import { addCategory,  updateCategory } from '../../services/operations/syllabus';

const Category = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch()

    const { allCategories } = useSelector((state) => state.syllabus)
    const { isProd } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(setCategory({}))
    }, [dispatch]);


    const { category } = useSelector((state) => state.syllabus)
    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    useEffect(() => {
        reset(category)
    }, [reset, category]);


    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            name: '',
            rank: '',
            percentage: ''
        }


        dispatch(setCategory(data))
        setIsEdit(false)
        setModalOpen(true);
    }
    const onSubmit = async (data) => {

        if (isEdit) {
            data.rank = parseInt(data.rank);
            data.percentage = parseFloat(data.percentage);
            const { name, rank, percentage } = data;
            dispatch(updateCategory(encodeURIComponent(category?.id), name, rank, percentage, setModalOpen, isProd))

        } else {
            data.rank = parseInt(data.rank);
            data.percentage = parseFloat(data.percentage);
            const { name, rank, percentage } = data;
            dispatch(addCategory(name, rank, percentage, setModalOpen, isProd))

        }

    }


    // console.log(allCategories)
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add</button>
                        </div>
                        <CategoryModal title='Add Category' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='name'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Rank</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="rank"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="rank"
                                                    type="number"
                                                    name="rank"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='rank'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Rank is required',
                                            }}
                                        />
                                        {isSubmitted && errors.rank && <p className="text-red-600 text-sm italic">{errors.rank.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Percentage</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="percentage"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="percentage"
                                                    type="number"
                                                    step="any"
                                                    name="percentage"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder='percentage'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Percentage is required',
                                            }}
                                        />
                                        {isSubmitted && errors.percentage && <p className="text-red-600 text-sm italic">{errors.percentage.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                        {allCategories && allCategories.length > 0 ? (
                            <>
                                <CategoryTable page="Category" setIsEdit={setIsEdit} topics={allCategories} setModalOpen={setModalOpen} modalOpen={modalOpen} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Category
                            </div>
                        )}

                    </div>
                </main>
            </div>
        </div>
    )
}

export default Category