/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Transition from '../../utils/Transition';

const SearchModal = ({
    id,
    searchId,
    modalOpen,
    setModalOpen
}) => {
    const modalContent = useRef(null);
    const searchInput = useRef(null);

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredDrafts, setFilteredDrafts] = useState([]);
    const [filteredPending, setFilteredPending] = useState([]);
    const [filteredPublished, setFilteredPublished] = useState([]);
    const { signinData } = useSelector((state) => (state.auth))

    const { token } = useSelector((state) => state.auth)

    const dispatch = useDispatch();




    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!modalOpen || modalContent.current.contains(target)) return
            setModalOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!modalOpen || keyCode !== 27) return;
            setModalOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        modalOpen && searchInput.current.focus();
    }, [modalOpen]);

    const email = signinData?.email

    // useEffect(() => {
    //     if (token) {
    //         if (!pending?.length || pending?.length) {
    //             dispatch(getPending(email))
    //         }
    //     }
    // }, [dispatch, pending?.length, email, token])

    // useEffect(() => {
    //     if (token) {
    //         if (!published?.length) {
    //             dispatch(getPublished(email))
    //         }
    //     }
    // }, [dispatch, published?.length, email, token])

    // useEffect(() => {
    //     if (token) {
    //         if (!drafts?.length) {
    //             dispatch(getDrafts(email));
    //         }
    //     }
    // }, [dispatch, drafts?.length, email, token])


    return (
        <>
            {/* Modal backdrop */}
            <Transition
                className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
                show={modalOpen}
                enter="transition ease-out duration-200"
                enterStart="opacity-0"
                enterEnd="opacity-100"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                aria-hidden="true"
            />
            {/* Modal dialog */}
            <Transition
                id={id}
                className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
                role="dialog"
                aria-modal="true"
                show={modalOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
                <div ref={modalContent} className="bg-white dark:bg-dark_50 overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg">
                    {/* Search form */}
                    <div className="border-b-2 border-gray-200 dark:border-lead500 ">
                        <div className="relative">
                            <label htmlFor={searchId} className="sr-only">Search</label>
                            <input
                                id={searchId}
                                className="w-full border-0 focus:ring-transparent placeholder-gray-400 appearance-none py-3 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200"
                                type="search" placeholder="Search Anything…" ref={searchInput}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button className="absolute inset-0 right-auto group" aria-label="Search">
                                <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                    <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="py-4 px-2">
                        <div className="mb-3 last:mb-0">
                            <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Drafts</div>
                            {searchQuery && (
                                <div className="text-sm">
                                    {filteredDrafts?.map((draft) => (
                                        <div>
                                            <Link
                                                className="flex items-center p-2 text-gray-800 hover:text-white hover:bg-brand_40 rounded group dark:text-zinc-200"
                                                key={draft.id}
                                                to={`/problem-setter/draft/${draft.id}/edit`}
                                            >
                                                <svg className="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                                                    <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                                                </svg>
                                                <span>{draft.problem_name}</span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-3 last:mb-0">
                            <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Pending</div>
                            {searchQuery && (
                                <div className="text-sm">
                                    {filteredPending?.map((draft) => (
                                        <div>
                                            <Link
                                                className="flex items-center p-2 text-gray-800 hover:text-white hover:bg-brand_40 rounded group dark:text-zinc-200"
                                                key={draft.id}
                                                to={`/pending/${draft.id}/edit`}
                                            >
                                                <svg className="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                                                    <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                                                </svg>
                                                <span>{draft.problem_name}</span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {signinData?.role === 'Admin' && (
                            <div className="mb-3 last:mb-0">
                                <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Published</div>
                                {searchQuery && (
                                    <div className="text-sm">
                                        {filteredPublished?.map((draft) => (
                                            <div>
                                                <Link
                                                    className="flex items-center p-2 text-gray-800 hover:text-white hover:bg-brand_40 rounded group dark:text-zinc-200"
                                                    key={draft.id}
                                                    to={`/problem-setter/draft/${draft.id}/edit`}
                                                >
                                                    <svg className="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                                                        <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                                                    </svg>
                                                    <span>{draft.problem_name}</span>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
            </Transition>
        </>
    );
}

export default SearchModal