import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    daywiseDoubtCost: [],
    discussions: { data: [], currentPage: 1 },
};

const commentSlice = createSlice({
    name: "comments",
    initialState: initialState,
    reducers: {
        setDaywiseDoubtCost(state, value) {
            state.daywiseDoubtCost = value.payload;
        },
        setDiscussions(state, action) {
            state.discussions = {
                data: action.payload.discussions || [],
                currentPage: action.payload.page || 1,
            };
        },
    },
});

export const { setDaywiseDoubtCost, setDiscussions } = commentSlice.actions;
export default commentSlice.reducer;