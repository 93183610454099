import React, { useState } from 'react';

function ContentAccordion(props) {

    const [open, setOpen] = useState(false);

    return (
        <>
            <div
                key={props.id}
                className={`w-full mt-4 flex bg-white dark:bg-dark_50 flex-col shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] ${open && 'shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px]'} rounded-md border border-gray-200 dark:border-zinc-800`}
            >
                <div className="px-3 py-2 ">
                    <button
                        className="flex items-center justify-between w-full group mb-1"
                        aria-expanded={open}
                        onClick={() => setOpen(!open)}
                    >
                        <div className={`text-lg  font-bold ${open ? 'text-brand' : 'text-gray-800 dark:text-zinc-300'}`}>{props.title} {props.numberOfProblems ? <span className='text-md font-normal ml-2'> ({props.numberOfProblems})</span> : null}</div>

                        <div className='flex items-center gap-x-2'>
                            <svg className={`w-8 h-8 flex-shrink-0  ml-3  rounded-lg text-sm border  ${open ? 'rotate-180 fill-brand text-brand_50 group-hover:text-brand border-brand_20 bg-[#FDEDEA] dark:bg-[#ee4c2b39] dark:border-[#ee4c2b88]' : 'fill-current text-gray-400 group-hover:text-gray-500 dark:bg-dark_40 border-zinc-300 dark:border-zinc-800 bg-[#fafafa]'}`} viewBox="0 0 32 32">
                                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                            </svg>
                        </div>
                    </button>
                    <div className={`text-sm dark:text-zinc-300 ${!open && 'hidden'}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}


export default ContentAccordion