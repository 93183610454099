import React, { useState } from 'react'
import { setCouponCode } from '../../../slices/premiumSlice'
import { useDispatch } from 'react-redux'
import CategoryModal from '../../Shared/Modals/CategoryModal'
import { deleteCoupon } from '../../../services/operations/premium'

const CouponsTableItem = (props) => {
    const { setModalOpen, setIsEdit } = props

    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

    const handlePlanEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            name: props.name,
            discount: props.discount,
            maxCount: props.maxCount,
            isActive: props.isActive,
            isPublic: props.isPublic
        }
        dispatch(setCouponCode(data))
        setIsEdit(true)
        setModalOpen(true);
    }
    const handlePlanDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }

    const handlePlanDelete = (e) => {
        dispatch(deleteCoupon(props.id, setModalDeleteOpen))

    }

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.name}
                    </div>
                </td>

                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.discount}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.maxCount}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.usageCount}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.isActive === 1 ? 'True' : 'False'}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.isPublic === 1 ? 'True' : 'False'}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handlePlanDeleteModal} className='btn bg-red-500 border-red-500 text-white  transition-all duration-300 ease-in-out'>Delete</button>
                        <button onClick={handlePlanEdit} className='btn bg-green-500 border-green-500 text-white  transition-all duration-300 ease-in-out'>Edit</button>
                    </div>
                </td>
            </tr>
            <CategoryModal title={"Delete Coupon Code"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handlePlanDelete()}>Yes, Delete</button>
                </div>
            </CategoryModal>
        </tbody>
    )
}

export default CouponsTableItem