import React, { useState } from 'react'
import { setUser } from '../../../slices/grantAccessSlice'
import { useDispatch } from 'react-redux'
import UsersModal from '../../Shared/Modals/UsersModal'
import { deleteUser } from '../../../services/operations/grantAccess'

const UsersTableItem = (props) => {
    const { setModalOpen, setIsEdit } = props

    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handleUserEdit = (e) => {
        e.stopPropagation()
        const data = {
            name: props.name,
            email: props.email,
            plan_name: props.planName,
            validity: props.validity,
            isPremium: props.isPremium,
            medium: props.medium
        }
        dispatch(setUser(data))
        setIsEdit(true)
        setModalOpen(true)
    }
    const handleUserDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }
    
    const handleDeleteUser = (e) => {
        dispatch(deleteUser(encodeURIComponent(props?.userId), setModalDeleteOpen))  
    }


    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.name}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.email}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.planName}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.validity}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.isPremium}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleUserEdit} className='btn bg-green-500 text-white  border-green-500 transition-all duration-300 ease-in-out '>Edit</button>
                        <button onClick={handleUserDeleteModal} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                    </div>
                </td>
            </tr>
            <UsersModal title={"Delete User"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleDeleteUser()}>Yes, Delete</button>
                </div>
            </UsersModal> 
        </tbody >
    )
}

export default UsersTableItem