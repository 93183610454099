import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import UsersTable from './UsersTable';
import { getAllUsers, setUser } from '../../services/operations/auth';
import { Eye, EyeOff, LockKeyhole } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
// import { setUser } from '../../../services/operations/auth';

const AddUser = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { signinData } = useSelector((state) => (state.auth))

    const navigate = useNavigate()

    useEffect(() => {
        if (signinData?.role === 'Intern') {
            navigate('/admin')
        }
    }, [signinData?.role, navigate])
    const handleModalOpen = (e) => {
        e.stopPropagation();

        setModalOpen(true);
    }


    const { allUsers } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({ mode: 'all' });


    const isPasswordValid = (value) => {
        return value.length >= 6 || 'Password must be at least 6 characters';
    };

    const handleOnSubmit = (data) => {

        const { userName, password, role } = data;
        dispatch(setUser(userName, password, role, setModalOpen))
        setValue('userName', '');
        setValue('email', '');
        setValue('password', '');
        setValue('role', '');
    }


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add</button>
                            <div className=" relative w-fit">
                                <label className="sr-only">Search</label>
                                <input
                                    className="w-full border rounded-lg focus:ring-transparent placeholder-gray-400 appearance-none py-2 pl-10 pr-4 dark:bg-dark_50 dark:placeholder:text-zinc-200 dark:text-zinc-200 border-slate-300 bg-white placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 hover:border-slate-400 dark:hover:border-slate-500"
                                    type="search" placeholder="Search…"
                                // value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="absolute inset-0 right-auto group" aria-label="Search">
                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        {allUsers && allUsers.length > 0 ? (
                            <>
                                <UsersTable topics={allUsers} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No user to show
                            </div>
                        )}
                        <CategoryModal title='Add User' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className=''>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3">
                                        <Controller
                                            name="userName"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    name="userName"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Username"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Username is required',
                                            }}
                                        />
                                        {errors.username && <p className="text-red-600 text-sm italic">{errors.username.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3  relative ">
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        className="form-input w-full text-gray-300 py-2 pl-10 pr-4"
                                                        placeholder="Password (at least 10 characters)"
                                                        {...field}
                                                    />
                                                    <LockKeyhole className="absolute inset-1 right-auto left-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                    {showPassword ? (
                                                        <Eye onClick={(e) => { e.stopPropagation(); setShowPassword(!showPassword) }} className="absolute inset-1 left-auto right-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                    ) : (
                                                        <EyeOff onClick={(e) => { e.stopPropagation(); setShowPassword(!showPassword) }} className="absolute inset-1 left-auto right-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                    )}
                                                </>
                                            )}
                                            rules={{
                                                required: 'Password is required',
                                                validate: {
                                                    isPasswordValid,
                                                },
                                            }}
                                        />
                                        {errors.password && <p className="text-red-600 text-sm italic">{errors.password.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3">
                                        <Controller
                                            name="role"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="">Select Role...</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="Intern">Intern</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Role is required',
                                            }}
                                        />
                                        {errors.role && <p className="text-red-600 text-sm italic">{errors.role.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand">Add User</button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddUser