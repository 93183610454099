import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useParams } from 'react-router-dom';
import { getProblem } from '../../../services/operations/syllabus';
import Prism from "prismjs";
import "prismjs/components/prism-java";
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-python";
import './editorial.css';
import CodeModal from './CodeModal';
const EditorialContentPreview = () => {

    const [openTab, setOpenTab] = useState(() => parseInt(1));
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { problem } = useSelector((state) => state.syllabus)
    const [imagesModalOpen, setImagesModalOpen] = useState(false);
    const [problemImages, setProblemImages] = useState([]);
    const dispatch = useDispatch()
    const params = useParams();

    useEffect(() => {
        dispatch(getProblem(encodeURIComponent(params?.id)))
    }, [dispatch, params?.id])

    useEffect(() => {
        Prism.highlightAll();
    }, [problem]);

    useEffect(() => {
        const codeSections = document.querySelectorAll('.code-section');
        // Get last selected language from localStorage or set default value
        let lastSelectedLanguage = localStorage.getItem('lastSelectedLanguage') || 'cpp';

        codeSections.forEach((section, sectionIndex) => {
            const codeTabs = section.querySelectorAll('.code-tab');
            const codeBlocks = section.querySelectorAll('.code-block');
            const copyBtn = section.querySelector('.copy-btn');

            codeTabs.forEach((tab) => {
                tab.addEventListener('click', () => {
                    codeTabs.forEach((t) => {
                        t.classList.remove('dsa_article_code_active');
                    });
                    tab.classList.add('dsa_article_code_active');

                    const lang = tab.dataset.lang;
                    const activeBlock = section.querySelector(`.code-block[data-lang="${lang}"]`);
                    codeBlocks.forEach((block) => {
                        if (block === activeBlock) {
                            block.classList.add('dsa_article_code_active');
                        } else {
                            block.classList.remove('dsa_article_code_active');
                        }
                    });

                    // Update last selected language for all sections and store in localStorage
                    lastSelectedLanguage = lang;
                    localStorage.setItem('lastSelectedLanguage', lang);
                    codeSections.forEach((otherSection, otherIndex) => {
                        if (otherIndex !== sectionIndex) {
                            const otherTabs = otherSection.querySelectorAll('.code-tab');
                            otherTabs.forEach((otherTab) => {
                                if (otherTab.dataset.lang === lang) {
                                    otherTab.classList.add('dsa_article_code_active');
                                } else {
                                    otherTab.classList.remove('dsa_article_code_active');
                                }
                            });
                            const otherActiveBlock = otherSection.querySelector(`.code-block[data-lang="${lang}"]`);
                            const otherCodeBlocks = otherSection.querySelectorAll('.code-block');
                            otherCodeBlocks.forEach((block) => {
                                if (block === otherActiveBlock) {
                                    block.classList.add('dsa_article_code_active');
                                } else {
                                    block.classList.remove('dsa_article_code_active');
                                }
                            });
                        }
                    });
                });
            });

            // Handle copy button click event
            copyBtn.addEventListener('click', () => {
                const activeBlock = section.querySelector('.code-block.dsa_article_code_active code');
                navigator.clipboard.writeText(activeBlock.innerText);
                copyBtn.classList.add('dsa_article_code_active');
                setTimeout(() => {
                    copyBtn.classList.remove('dsa_article_code_active');
                }, 1000);
            });
        });

        // Set initial state of the tabs
        codeSections.forEach((section) => {
            const codeTabs = section.querySelectorAll('.code-tab');
            const codeBlocks = section.querySelectorAll('.code-block');

            codeTabs.forEach((tab) => {
                if (tab.dataset.lang === lastSelectedLanguage) {
                    tab.classList.add('dsa_article_code_active');
                } else {
                    tab.classList.remove('dsa_article_code_active');
                }
            });

            codeBlocks.forEach((block) => {
                if (block.dataset.lang === lastSelectedLanguage) {
                    block.classList.add('dsa_article_code_active');
                } else {
                    block.classList.remove('dsa_article_code_active');
                }
            });
        });
    })


    // console.log(document.querySelectorAll('.carousel-image')?.length)
    useEffect(() => {
        let currentIndex = 0;
        let intervalId;

        const carousel = document.querySelector('.carousel');
        const images = carousel ? carousel.querySelectorAll('.carousel-image') : []
        const playButton = document.querySelector('.image-carousel-play-button');
        const pauseButton = document.querySelector('.image-carousel-pause-button');
        const leftArrow = document.querySelector('.image-carousel-left-arrow');
        const rightArrow = document.querySelector('.image-carousel-right-arrow');
        const imageNumber = document.querySelector('.image-number');

        function updateCarousel() {
            if (carousel) {
                carousel.style.transform = `translateX(-${currentIndex * 100}%)`;
            }
            updateImageNumber();
        }

        function updateImageNumber() {
            if (imageNumber) {
                imageNumber.textContent = `${currentIndex + 1}/${images.length}`;
            }
        }

        function nextImage() {
            currentIndex = (currentIndex + 1) % images.length;
            updateCarousel();
        }

        function prevImage() {
            currentIndex = (currentIndex - 1 + images.length) % images.length;
            updateCarousel();
        }

        function playSlideshow() {
            intervalId = setInterval(nextImage, 2000);
            playButton.style.display = 'none';
            pauseButton.style.display = 'inline-block';
        }

        function pauseSlideshow() {
            clearInterval(intervalId);
            playButton.style.display = 'inline-block';
            pauseButton.style.display = 'none';
        }

        leftArrow?.addEventListener('click', prevImage);
        rightArrow?.addEventListener('click', nextImage);
        playButton?.addEventListener('click', playSlideshow);
        pauseButton?.addEventListener('click', pauseSlideshow);

        updateCarousel();

        // Clean up event listeners on unmount
        return () => {
            leftArrow?.removeEventListener('click', prevImage);
            rightArrow?.removeEventListener('click', nextImage);
            playButton?.removeEventListener('click', playSlideshow);
            pauseButton?.removeEventListener('click', pauseSlideshow);

            clearInterval(intervalId);
        };
    });

    useEffect(() => {
        const carousel = document.querySelector('.carousel');
        if (carousel) {
            const downButton = document.querySelector('.image-carousel-down-button');
            if (downButton) {
                downButton.addEventListener('click', (e) => {
                    e.stopPropagation();
                    const images = carousel.querySelectorAll('.carousel-image img');
                    const imageSrcs = Array.from(images).map(img => img.src);
                    setImagesModalOpen(true)
                    setProblemImages(imageSrcs);
                });
            }
        }
    });

    return (
        <div className="flex bg-[#F1F5F9] dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="bg-white dark:bg-transparent mb-8">
                            <div className="flex-grow font-primary">
                                <section className='p-10 flex flex-col'>
                                    <div className="flex flex-wrap">
                                        <div className="w-full">
                                            <div
                                                className="flex list-none overflow-x-auto  pt-3 pb-4 flex-row no-scrollbar"
                                                role="tablist"
                                            >
                                                {problem && problem?.parts?.map((part, index) => (
                                                    <>
                                                        <div className="-mb-px flex-auto text-center">
                                                            <a
                                                                className={`
                                                                     text-sm font-bold uppercase px-4 py-3 flex gap-x-2  justify-center items-center leading-normal     
                                                                    ${openTab === index + 1
                                                                        ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                                                                        : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
                                                                    }
                                                                `}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setOpenTab(index + 1);
                                                                }}
                                                                data-toggle="tab"
                                                                href="#problem"
                                                                role="tablist"
                                                            >
                                                                <span>
                                                                    {part.name}
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </>
                                                ))}


                                            </div>
                                        </div>
                                        <div className="relative flex flex-col min-w-0 break-words  w-full mb-2">
                                            <div className="tab-content tab-space ">
                                                {problem && problem?.parts?.map((part, index) => (
                                                    <div className={openTab === index + 1 ? "block dark:bg-dark_50 bg-white p-4 border rounded-lg border-light_10 dark:border-dark_40 flex-auto" : "hidden"} id="problem">
                                                        {part?.editorial ?
                                                            <div className='article' dangerouslySetInnerHTML={{ __html: part?.editorial }} />
                                                            :
                                                            <p className='text-light_50 dark:text-zinc-300'>No editorial provided</p>
                                                        }
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main >
            </div >
            <CodeModal hasCopy={false} title={"Images"} setModalOpen={setImagesModalOpen} modalOpen={imagesModalOpen}>
                <div className='p-4'>
                    {problemImages && problemImages?.length > 0 ? (
                        <div className='flex flex-col gap-y-4'>
                            {problemImages?.map((data, index) => (
                                <img className='h-[300px] w-full object-contain' src={data} alt={`alt${index}`} />
                            ))}
                        </div>
                    ) : (
                        <>
                            No Images
                        </>
                    )}

                </div>
            </CodeModal >
        </div >
    )
}

export default EditorialContentPreview