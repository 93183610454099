import { ClipboardCopy } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { cleanCategoryName, generateImageCarousel, isObjectEmpty } from '../../utils/Utils';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CodeModal from '../SyllabusContent/Editorials/CodeModal';
import {  useSelector } from 'react-redux';
import axios from 'axios';

const CreateImageSlider = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [numberOfImages, setNumberOfImages] = useState(1);
    const [imageSliderString, setImageSliderString] = useState(``);
    const [imagesModalOpen, setImagesModalOpen] = useState(false);
    const [problemImages, setProblemImages] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
    const [selectedProblemName, setSelectedProblemName] = useState('');
    const [files, setFiles] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [fileLinks, setFileLinks] = useState({});
    const [imagePreviews, setImagePreviews] = useState({});
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
    const [selectedProblemId, setSelectedProblemId] = useState('');
    const { handleSubmit } = useForm();

    const { allCategories, allSubCategories, allProblems } = useSelector(state => state.syllabus);


    const handleCategoryChange = (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        setSelectedCategoryId(e.target.value);
        setSelectedCategoryName(e.target.options[selectedIndex].text);
        setSelectedSubCategoryId(''); // Reset subcategory on category change
        setSelectedSubCategoryName(''); // Reset subcategory name
        setSelectedProblemId(''); // Reset problem on category change
        setSelectedProblemName(''); // Reset problem name
    };

    const handleSubCategoryChange = (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        setSelectedSubCategoryId(e.target.value);
        setSelectedSubCategoryName(e.target.options[selectedIndex].text);
        setSelectedProblemId(''); // Reset problem on subcategory change
        setSelectedProblemName(''); // Reset problem name
    };

    const handleProblemChange = (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        setSelectedProblemId(e.target.value);
        setSelectedProblemName(e.target.options[selectedIndex].text);
    };

    const handleFileChange = (e, index) => {
        const selectedFile = e.target.files[0];
        const newFiles = { ...files };
        newFiles[`file${index}`] = selectedFile;
        setFiles(newFiles);
        if (selectedFile) {
            const newImagePreviews = { ...imagePreviews };
            newImagePreviews[`file${index}`] = URL.createObjectURL(selectedFile);
            setImagePreviews(newImagePreviews);
        }
    };


    const handleFileUpload = async () => {
        const uploadPromises = Object.keys(files).map((key) => {
            const selectedFile = files[key];
            if (!selectedFile) {
                toast.error(`No file selected for ${key}`);
                return Promise.resolve(null); // Return a resolved promise with null to maintain the same promise structure
            }

            let formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('fileName', selectedFile.name);
            formData.append('categoryName', selectedCategoryName);
            formData.append('subCategoryName', selectedSubCategoryName);
            formData.append('problemName', selectedProblemName);

            return axios.put(`${process.env.REACT_APP_BACKEND_URL_PROD}/uploadImagePremium`, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
                withCredentials: true,
            })
                .then((response) => {
                    if (response.data.success) {
                        return { key, link: response.data.link };
                    } else {
                        toast.error(`Failed to upload ${key}`);
                        return null;
                    }
                })
                .catch((error) => {
                    toast.error(`Failed to upload ${key}`);
                    console.error(error);
                    return null;
                });
        });

        const uploadResults = await Promise.all(uploadPromises);
        const validResults = uploadResults.filter(item => item !== null);
        const result = validResults.reduce((acc, item) => {
            acc[item.key] = item.link;
            return acc;
        }, {});

        if (result && result) {
            setFileLinks(result);
        }
        return result; // Return the result so the promise resolves with the data
    };

    const cleanedSubCategories = allSubCategories.map(category => ({
        ...category,
        subcategories: category?.subcategories?.map(subcat => ({
            ...subcat,
            name: cleanCategoryName(subcat?.name)
        }))
    }));
    const filteredSubCategories = cleanedSubCategories?.find(sc => sc?.categoryId === selectedCategoryId)?.subcategories || [];

    const cleanedCategories = allCategories.map(cat => ({
        ...cat,
        name: cleanCategoryName(cat?.name)
    }));

    const category = allProblems.find(category => category?.id === selectedCategoryId);
    const subcategory = category && category?.subcategories.find(sub => sub.id === selectedSubCategoryId);
    const filteredProblems = subcategory?.problems || [];
    const onSubmit = async () => {
        try {
            const fileLinksResult = await handleFileUpload();
            const formattedData = [];

            console.log("File Links ", fileLinksResult);
            for (let i = 1; i <= numberOfImages; i++) {
                if (!isObjectEmpty(fileLinksResult)) {
                    const link = fileLinksResult[`file${i}`];
                    if (link) {
                        formattedData.push({ link });
                    }
                }
            }
            setImageSliderString(generateImageCarousel(formattedData));
            setSelectedCategoryId('');
            setSelectedCategoryName('');
            setSelectedSubCategoryId(''); // Reset subcategory on category change
            setSelectedSubCategoryName(''); // Reset subcategory name
            setSelectedProblemId(''); // Reset problem on category change
            setSelectedProblemName(''); // Reset problem name
            setFiles({});
            setFileLinks({});
            setImagePreviews({});
            setNumberOfImages(1);
        } catch (error) {
            console.error("Error in onSubmit: ", error);
        }
    };

    const handleNumberOfImagesChange = (event) => {
        setNumberOfImages(parseInt(event.target.value));
    };

    useEffect(() => {
        let currentIndex = 0;
        let intervalId;

        const carousel = document.querySelector('.carousel');
        const images = carousel ? carousel.querySelectorAll('.carousel-image') : []
        const playButton = document.querySelector('.image-carousel-play-button');
        const pauseButton = document.querySelector('.image-carousel-pause-button');
        const leftArrow = document.querySelector('.image-carousel-left-arrow');
        const rightArrow = document.querySelector('.image-carousel-right-arrow');
        const imageNumber = document.querySelector('.image-number');

        function updateCarousel() {
            if (carousel) {
                carousel.style.transform = `translateX(-${currentIndex * 100}%)`;
            }
            updateImageNumber();
        }

        function updateImageNumber() {
            if (imageNumber) {
                imageNumber.textContent = `${currentIndex + 1}/${images.length}`;
            }
        }

        function nextImage() {
            currentIndex = (currentIndex + 1) % images.length;
            updateCarousel();
        }

        function prevImage() {
            currentIndex = (currentIndex - 1 + images.length) % images.length;
            updateCarousel();
        }

        function playSlideshow() {
            intervalId = setInterval(nextImage, 2000);
            playButton.style.display = 'none';
            pauseButton.style.display = 'inline-block';
        }

        function pauseSlideshow() {
            clearInterval(intervalId);
            playButton.style.display = 'inline-block';
            pauseButton.style.display = 'none';
        }

        leftArrow?.addEventListener('click', prevImage);
        rightArrow?.addEventListener('click', nextImage);
        playButton?.addEventListener('click', playSlideshow);
        pauseButton?.addEventListener('click', pauseSlideshow);

        updateCarousel();

        // Clean up event listeners on unmount
        return () => {
            leftArrow?.removeEventListener('click', prevImage);
            rightArrow?.removeEventListener('click', nextImage);
            playButton?.removeEventListener('click', playSlideshow);
            pauseButton?.removeEventListener('click', pauseSlideshow);

            clearInterval(intervalId);
        };
    });

    useEffect(() => {
        const carousel = document.querySelector('.carousel');
        if (carousel) {
            const downButton = document.querySelector('.image-carousel-down-button');
            if (downButton) {
                downButton.addEventListener('click', (e) => {
                    e.stopPropagation();
                    const images = carousel.querySelectorAll('.carousel-image img');
                    const imageSrcs = Array.from(images).map(img => img.src);
                    setImagesModalOpen(true)
                    setProblemImages(imageSrcs);
                });
            }
        }
    });

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(imageSliderString);
            toast.success("Copied Successfully")
        } catch (error) {
            toast.error("Failed to copy clipboard")
        }
    };

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa] dark:text-[#f5f5f5]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <form className='w-3/5 mx-auto bg-white dark:bg-dark_50 p-6 rounded-lg border border-zinc-300 dark:border-zinc-800' onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-x-4 w-full mb-10'>
                                <select value={selectedCategoryId} onChange={(e) => handleCategoryChange(e)} className='form-select w-full'>
                                    <option value="">Select Category</option>
                                    {cleanedCategories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                                <select value={selectedSubCategoryId} onChange={(e) => handleSubCategoryChange(e)} className='form-select w-full'>
                                    <option value="">Select Subcategory</option>
                                    {filteredSubCategories.map(sub => (
                                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                                    ))}
                                </select>
                                <select value={selectedProblemId} onChange={(e) => handleProblemChange(e)} className='form-select w-full'>
                                    <option value="">Select Problem</option>
                                    {filteredProblems.map(prob => (
                                        <option key={prob.problem_id} value={prob.problem_id}>{prob.problem_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-y-1'>
                                <label htmlFor="numberOfImages">Select Number of Images:</label>
                                <select
                                    id="numberOfImages"
                                    name="numberOfImages"
                                    className='form-select'
                                    value={numberOfImages}
                                    onChange={handleNumberOfImagesChange}
                                >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </select>
                            </div>
                            <div className='mt-4 mb-8'>
                                {[...Array(numberOfImages)].map((_, index) => (
                                    <div key={index} className='flex flex-col gap-y-4 mt-8'>
                                        <div className='flex flex-col gap-y-2'>
                                            <label>Image {index + 1}:</label>
                                            <div className='flex items-center w-full gap-x-4'>
                                                <input className='w-full' type='file' onChange={(e) => handleFileChange(e, index + 1)} />
                                                {imagePreviews[`file${index + 1}`] && <img src={imagePreviews[`file${index + 1}`]} alt={`Preview ${index + 1}`} className="h-24 w-24 object-contain" />}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <button className='btn w-full' type="submit">Submit</button>
                        </form>

                        {imageSliderString && (
                            <div className='mt-8 mx-auto flex justify-center items-center w-3/5'>
                                <div className='flex flex-col gap-y-4 justify-between w-full p-2 rounded-lg border border-zinc-400 dark:border-zinc-800 dark:bg-dark_50 bg-[#f5f5f5]'>
                                    <button onClick={copyToClipboard}>
                                        <ClipboardCopy className='hover:stroke-brand' />
                                    </button>
                                    <span className='w-full max-w-11/12'>
                                        {imageSliderString}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: imageSliderString }} />
                    </div>
                    <CodeModal hasCopy={false} title={"Images"} setModalOpen={setImagesModalOpen} modalOpen={imagesModalOpen}>
                        <div className='p-4'>
                            {problemImages && problemImages?.length > 0 ? (
                                <div className='flex flex-col gap-y-4'>
                                    {problemImages?.map((data, index) => (
                                        <img className='h-[300px] w-full object-contain' src={data} alt={`alt${index}`} />
                                    ))}
                                </div>
                            ) : (
                                <>
                                    No Images
                                </>
                            )}
                        </div>
                    </CodeModal >
                </main>
            </div>
        </div>
    )
}

export default CreateImageSlider
