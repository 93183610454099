import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoubtCostTable from "../../components/Table/CommentsTable/DoubtCostTable";
import DoubtCostChart from "../../components/Charts/DoubtCostChart";
import { getDoubtCost } from "../../services/operations/comments"
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";

const DoubtCostInfo = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const { daywiseDoubtCost } = useSelector((state) => state.comments);

  const [startDate, setStartDate] = useState("2024-06-07");
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 19));
  
  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10);
    setEndDate(today);
  }, []); 

  useEffect(() => {
      dispatch(getDoubtCost(startDate, endDate));
  }, [dispatch, startDate, endDate]);

  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
          <div className='flex items-center space-x-4 mb-4'>
            <input
              type='date'
              className='border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type='date'
              className='border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <DoubtCostTable totalCosts={daywiseDoubtCost} type='doubtCost' />
          <div className='bg-[#fafafa] shadow-lg rounded-lg dark:bg-[#212121]  p-8'>
            <h3 className='text-2xl font-semibold mb-2 text-gray-800 dark:text-gray-100'>
              Doubt Costs
            </h3>
            <DoubtCostChart data={daywiseDoubtCost} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoubtCostInfo;
