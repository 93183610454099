import { setAllConfigs } from "../../slices/configSlice"
import { configEndpoints} from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { ADD_CONFIG, EDIT_CONFIG, DELETE_CONFIG, GET_ALL_CONFIG } = configEndpoints;

export function getAllConfigs() {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_CONFIG);
            if (response.data.success){
                dispatch(setAllConfigs(response.data.config_details));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function addConfig(key, value, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_CONFIG, { key, value })
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllConfigs());
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add config");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateConfig(id, key, value, setModalOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", EDIT_CONFIG + `${id}`, { key, value });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllConfigs());
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update config");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteConfig(id, setModalDeleteOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", DELETE_CONFIG + `${id}`);
            if(response.data.success) {
                dispatch(getAllConfigs());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete config");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}