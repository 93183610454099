import React from 'react';
import DoubtCostTableItem from './DoubtCostTableItem';
import JudgeStatsTableItem from '../JudgeStatsTable/JudgeStatsTableItem';

const DoubtCostTable = ({ totalCosts = [], stats = [], type }) => {
  return (
    <div className='font-primary  relative'>
      <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
        <div className='overflow-x-auto'>
          <table className='table-auto w-full  divide-y  divide-gray-200'>
            <thead className='text-xs uppercase  text-[#8C8C8C] '>
              <tr>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>No.</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Date</div>
                </th>
                {type === 'doubtCost' ? (
                  <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                    <div className='font-semibold text-center'>Total Cost</div>
                  </th>
                ) : (
                  <>
                    <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                      <div className='font-semibold text-center'>Total Run</div>
                    </th>
                    <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                      <div className='font-semibold text-center'>Total Submit</div>
                    </th>
                  </>
                )}
              </tr>
            </thead>

            {type === 'doubtCost' ? (
              <>
                {totalCosts?.map((data, index) => {
                  return (
                    <DoubtCostTableItem
                      num={index + 1}
                      date={data?.day}
                      totalCost={data?.total_cost}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {stats?.length > 0 ? (
                  stats.map((data, index) => (
                    <JudgeStatsTableItem
                      key={index}
                      num={index + 1}
                      date={data?.date}
                      totalRuns={data?.runCount}
                      totalSubmits={data?.submitCount}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan='5' className='text-center py-3'>
                      No data available                 
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default DoubtCostTable;
