/* eslint-disable no-unused-vars */
import React, {useState, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import { markStatus, sendEmailToUser } from "../../../services/operations/bugs"
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import NotificationsModal from '../../Shared/Modals/NotificationModal'
import ReactQuill from 'react-quill';

const BugsTableItem = (props) => {
    const {id,  status, title, description, reportedBy, num, priority, time, setContent, setModalOpen, email } = props
    const [bugStatus, setBugStatus] = useState(status);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const dispatch = useDispatch()

    const priorityOptions = ['resolved', 'unresolved', 'in progress', 'cannot be fixed', 'deferred'];

    const emailMessage = `
    <p>Hi,</p><br/>
    <p>Thank you for reaching out to us.</p>
    <p>Our team has resolved the bug titled <strong>${title}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
    <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
    <p>Thanks and Regards,<br/>takeUForward Team</p>
    `;

    const { handleSubmit: handleEmailSubmit, control: emailControl, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted } } = useForm({
        defaultValues: {
            sendEmailTitle: `TUF+ | Your BugId[${id}]`, 
            sendEmailMessage: emailMessage, 
        }
    });

    const handleStatusChange = async (newStatus) => {
        try {
            await dispatch(markStatus(id, newStatus)); 
            setBugStatus(newStatus);
            setEmailModalOpen(true);
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const handleView = (e) => {
        e.stopPropagation();
        setModalOpen(true)
        setContent(description)
    }
    
    const formatDate = useCallback((date) => {
        const d = new Date(date);
        return isNaN(d.getTime()) ? 'Invalid date' : d.toISOString().split('T')[0];
    // eslint-disable-next-line
    }, []);

    const handleSendEmail = async (data) => {
        const { sendEmailTitle, sendEmailMessage } = data;
        setIsSending(true);
        const toastId = toast.loading('Sending email. Please wait...');
        try {
            dispatch(sendEmailToUser(sendEmailTitle, sendEmailMessage, email));    
            toast.success('Email sent successfully!', { id: toastId });
        } catch (error) {
            toast.error('An error occurred while sending email.', { id: toastId });
        } 
        setIsSending(false);
        setEmailModalOpen(false);
    }

    return (
        <tbody className="text-[13px] overflow-y-auto">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {reportedBy}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {priority}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(time)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {title}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {status}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select 
                            value={bugStatus} 
                            onChange={(e) => handleStatusChange(e.target.value)} 
                            className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                        >
                            {priorityOptions.map(option => (
                                <option key={option} value={option} className="bg-white dark:bg-zinc-800 text-gray-800 dark:text-zinc-200">
                                    {option.charAt(0).toUpperCase() + option.slice(1)} 
                                </option>
                            ))}
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleView(e)} className="text-brand underline" type='button'>View</button>
                    </div>
                </td>
            </tr>
            <NotificationsModal title={'Send Email To User'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
                <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailTitle"
                                control={emailControl}
                                render={({ field }) => (
                                    <input
                                        id="sendEmailTitle"
                                        type="text"
                                        name="sendEmailTitle"
                                        className="form-input w-full text-gray-300"
                                        placeholder="Title"
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Title is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailMessage"
                                control={emailControl}
                                render={({ field }) => (
                                    <ReactQuill
                                        id="sendEmailMessage"
                                        theme="snow"
                                        name="sendEmailMessage"
                                        className="w-full text-gray-800 dark:text-zinc-200"
                                        placeholder="Email message to be sent."
                                        rows={5}
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Message is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                        </div>
                    </div>
                               
                    <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full px-3">
                            <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                        </div>
                    </div>
                </form>
            </NotificationsModal> 
        </tbody>
    )
}

export default BugsTableItem