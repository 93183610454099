import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Link, useParams } from 'react-router-dom';
import { addEditorials, getProblem } from '../../../services/operations/syllabus';
import { formatData, removeDuplicates } from '../../../utils/Utils';

const EditorialContent = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { problem } = useSelector((state) => state.syllabus)
    const { isProd } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const params = useParams();

    useEffect(() => {
        dispatch(getProblem(encodeURIComponent(params?.id)))
    }, [dispatch, params?.id])

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            editorials: problem?.parts?.reduce((acc, part) => {
                acc[part.id?.replace(/'/g, "__SINGLE_QUOTE__")?.replace(/,/g, "__COMMA__")?.replace("|", "__SINGLE_SLASH__")] = part.editorial;
                return acc;
            }, {})
        }
    });
    useEffect(() => {
        if (problem) {
            const defaultValues = problem?.parts?.reduce((acc, part) => {
                acc[part?.id?.replace(/'/g, "__SINGLE_QUOTE__")?.replace(/,/g, "__COMMA__")?.replace("|", "__SINGLE_SLASH__")] = part.editorial;
                return acc;
            }, {});
            reset({ editorials: defaultValues });
        }
    }, [reset, problem]);

    const onSubmit = (data) => {
        const newData = formatData(data.editorials)
        const newDataWithProblemId = newData.map(item => ({
            ...item,
            problem_id: decodeURIComponent(params?.id),
            partId: item?.partId?.replace(/__SINGLE_QUOTE__/g, "'").replace(/__COMMA__/g, ",").replace("__SINGLE_SLASH__", "|")
        }));

        let cleanedData = removeDuplicates(newDataWithProblemId);
        dispatch(addEditorials(cleanedData, null, isProd))
    }

    // console.log(problem?.parts?.length)

    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-[#f5f5f5]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='mt-4 mb-10'>
                            <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Note</span>
                                <div className="w-full px-3">
                                    {problem && problem?.note}
                                </div>
                            </div>
                        </div>
                        {problem && problem?.parts && problem?.parts?.length > 0 ?
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                {problem && problem?.parts && problem?.parts?.length > 0 && problem?.parts?.map((part, index) => (

                                    <div key={index} className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>{part.name} Editorial</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name={`editorials[${part?.id?.replace(/'/g, "__SINGLE_QUOTE__")?.replace(/,/g, "__COMMA__")?.replace("|", "__SINGLE_SLASH__")}]`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        rows={12}
                                                        id={part?.id?.replace(/'/g, "__SINGLE_QUOTE__")?.replace(/,/g, "__COMMA__")}
                                                        type="text"
                                                        name={`editorials[${part?.id?.replace(/'/g, "__SINGLE_QUOTE__")?.replace(/,/g, "__COMMA__")}]`}
                                                        className="form-textarea w-full text-gray-300"
                                                        placeholder={`${part.name} Editorial`}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                ))}
                                <div className="flex flex-row items-center -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                    </div>
                                    <Link to={`/syllabus-content/editorials/${encodeURIComponent(params?.id)}/preview`} type="button" className="btn text-[16px] font-medium w-full text-center">Preview</Link>
                                </div>
                            </form>
                            :
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No problem part to show
                            </div>
                        }

                    </div>
                </main>
            </div>
        </div>
    )
}

export default EditorialContent;
