import {  setAllReportedBugs, setProblemBugs, setEditorialBugs, setVideoBugs, setTechBugs, setOthersBugs, setBug } from "../../slices/bugSlice"
import { bugReportsEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_REPORTED_BUGS, MARK_STATUS, GET_ALL_BUGS, SEND_EMAIL , GET_BUG_BY_ID} = bugReportsEndpoints;

export function getReportedBugs(category, pageNumber, priority = null) {
    return async(dispatch) => {
        try {
            const priorityParam = priority ? `&priority=${encodeURIComponent(priority)}` : '';
            const response = await apiConnector("GET", `${GET_REPORTED_BUGS}${encodeURIComponent(category)}?page=${pageNumber}${priorityParam}`);
            if (response.data.success){
                switch (category) {
                    case 'problem':
                        dispatch(setProblemBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                    case 'editorial':
                        dispatch(setEditorialBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                    case 'video':
                        dispatch(setVideoBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                    case 'tech':
                        dispatch(setTechBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                    case 'others':
                        dispatch(setOthersBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                    default:
                        dispatch(setAllReportedBugs({ bugs: response.data.bugs, page: pageNumber }));
                        break;
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function markStatus(reportId, status) {  
    return async () => {
        try {
            const response = await apiConnector("POST", MARK_STATUS, { reportId , status })
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Failed to mark resolved");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}


export function sendEmailToUser(title, message, email) {
    return async () => {
        try {
            const response = await apiConnector("POST", SEND_EMAIL, { title, message, email })
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Failed to send email");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getAllReportedBugs() {  
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_BUGS)
            if (response.data.success) {
                dispatch(setAllReportedBugs(response.data.bugs));
            } else {
                toast.error(response.data.message || "Failed to mark resolved");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getBugById(taskId) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", GET_BUG_BY_ID, { taskId })
            if (response.data.success) {
                dispatch(setBug(response.data.bug));
            } else {
                toast.error(response.data.message || 'Failed to get bug.');
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}