import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    config: {},
    allConfigs: [],
};

const configSlice = createSlice({
    name: "configs",
    initialState: initialState,
    reducers: {
        setConfig(state, value) {
            state.config = value.payload;
        },
        setAllConfigs(state, value) {
            state.allConfigs = value.payload;
        },
    },
});

export const { setConfig, setAllConfigs } = configSlice.actions;
export default configSlice.reducer;