import React, { useState } from 'react'
import { setSession } from '../../../slices/sessionsSlice'
import { useDispatch } from 'react-redux'
import SessionsModal from '../../Shared/Modals/SessionsModal'
import { deleteSession, getSession } from '../../../services/operations/sessions'

const SessionsTableItem = (props) => {
    const { setModalOpen, setIsEdit, setContent, setCopyModalOpen } = props

    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handleSessionEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            meetingName: props.topic,
            meetingDate: props.date,
            meetingTime: props.time,
            meetingLink: props.link,
            maxPeople: props.maxPeople,
            enrolledPeople: props.enrolledPeople,
            video: props.video,
            thumbnail: props.thumbnail,
        }
        dispatch(setSession(data))
        setIsEdit(true)
        setModalOpen(true)
    }
    
    const formatDate = (date) => {
        const d = new Date(date);
        console.log("date frontend: ", d);
        if (isNaN(d.getTime())) {
            throw new Error("Invalid date format");
        }
        return d.toISOString().split('T')[0];
    };

    const handleSessionDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }
    
    const handleDeleteSession = (e) => {
        dispatch(deleteSession(props?.id, setModalDeleteOpen))
    }

    const handleView = (e) => {
        e.stopPropagation();
        setCopyModalOpen(true)
        dispatch(getSession(props?.id, setContent)); 
    }


    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props?.topic}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(props?.date)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.time}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.link}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.maxPeople}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.enrolledPeople}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleView(e)} className="text-brand underline" type='button'>View</button>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleSessionEdit} className='btn bg-green-500 text-white  border-green-500 transition-all duration-300 ease-in-out '>Edit</button>
                        <button onClick={handleSessionDeleteModal} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                    </div>
                </td>
            </tr>
            <SessionsModal title={"Delete Session"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleDeleteSession()}>Yes, Delete</button>
                </div>
            </SessionsModal>
        </tbody >
    )
}

export default SessionsTableItem