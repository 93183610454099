import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plan: {},
    allCouponCodes: [],
    couponCode: {},
    allPlans: [],
};

const premiumSlice = createSlice({
    name: "premium",
    initialState: initialState,
    reducers: {
        setPlan(state, value) {
            state.plan = value.payload;
        },
        setAllCouponCodes(state, value) {
            state.allCouponCodes = value.payload;
        },
        setCouponCode(state, value) {
            state.couponCode = value.payload;
        },
        setAllPlans(state, value) {
            state.allPlans = value.payload;
        },
    },
});

export const { setAllPlans, setCouponCode, setAllCouponCodes, setPlan } = premiumSlice.actions;

export default premiumSlice.reducer;