import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscussions } from '../../../services/operations/comments';
import DiscussionsTable from '../../../components/Table/DiscussionsTable/DiscussionsTable';
import CategoryModal from '../../../components/Shared/Modals/CategoryModal';
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'

const Discussions = () => {
    const dispatch = useDispatch();
    const { discussions } = useSelector((state) => state.comments);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    
    const [startDate, setStartDate] = useState("2024-08-07");
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 19));
    
    useEffect(() => {
        const today = new Date().toISOString().slice(0, 10);
        setEndDate(today);
    }, []); 

    useEffect(() => {
        dispatch(getDiscussions(startDate, endDate, currentPage));
    }, [dispatch, currentPage, startDate, endDate]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };


    return (
    <>
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="flex items-center space-x-4 mb-4">
                    <input
                    type="date"
                    className="border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                    type="date"
                    className="border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                {discussions.data && discussions.data.length > 0 ? (
                    <>
                        <DiscussionsTable 
                            setModalOpen={setModalOpen} 
                            setContent={setContent} 
                            discussions={discussions.data}
                            currentPage={currentPage}
                        />
                        <div className="flex justify-between mt-4">
                            <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
                        <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                    </div>
                )}
                <CategoryModal setModalOpen={setModalOpen} modalOpen={modalOpen}>
                    <div className="">
                        {content}
                    </div>
                </CategoryModal>
            </div>
        </div>
        </div>
    </>
    );
};

export default Discussions;
