import {  setAllAffiliateSales } from "../../slices/referralSlice"
import { referralEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_AFFILIATE_SALES, DEACTIVATE_REFERRAL_CODE, GENERATE_SHEET, REACTIVATE_REFERRAL_CODE  } = referralEndpoints;

export function getAffiliateSales() {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", GET_AFFILIATE_SALES);
            if (response.data.success){
                dispatch(setAllAffiliateSales(response.data.affiliates));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deactivateCode(couponCode) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", DEACTIVATE_REFERRAL_CODE, { couponCode })
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAffiliateSales());
            } else {
                toast.error(response.data.message || "Failed to deactivate code");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function reactivateCode(couponCode) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", REACTIVATE_REFERRAL_CODE, { couponCode });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAffiliateSales());
            } else {
                toast.error(response.data.message || "Failed to reactivate code");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function generatePaymentSheet() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", GENERATE_SHEET);
            if(response.data.success) {
                toast.success("Payment sheet generated successfully.");
                const sheetUrl = response.data.sheetUrl;
                window.open(sheetUrl, "_blank");
            } else {
                toast.error(response.data.message || "Failed to generate payment sheet");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}
